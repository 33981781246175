import {ActivatedRoute, Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {DOMStorageStrategy, GlobalCacheConfig} from 'ngx-cacheable';
import {RouterPage} from './abstract/RouterPage';



GlobalCacheConfig.storageStrategy = DOMStorageStrategy;

import { Auth0BusinessService } from './services/auth0/auth0business.service';
import {environment} from '../environments/environment';
import { enableProdMode } from '@angular/core';
if (environment.production) {
    enableProdMode();
    if(false && window){
        window.console.log = (...args) => {};
        window.console.dir = (...args) => {};
    }
}



@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent extends RouterPage   implements OnInit {



    constructor(
        private viewportScroller: ViewportScroller,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private route: ActivatedRoute,
        private auth0business: Auth0BusinessService
    ) {

        super(router, route);
        this.viewportScroller;
        this.initializeApp();



    }



    ngOnInit() {
        this.auth0business.localAuthSetup()
    }

    onEnter() {

        console.log("Eliminate credit card fees...  www.facepay.io ");

    }


    initializeApp() {




        this.platform.ready().then(() => {


            /* dont need this for browser app
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            */





            window.addEventListener('keyboardDidShow', () => {
                document.activeElement.scrollIntoView(false);
            });


/*
            this.activatedRoute.queryParams.subscribe(params => {
                alert("init params" + JSON.stringify(params));
            });

            alert("init url " + JSON.stringify(this.router.url));
*/

//            alert(JSON.stringify(this.route.snapshot.url));
//            alert(JSON.stringify(this.route.snapshot.data));

            /*
            this.authenticationService.authenticationState.subscribe(state => {
                if (state) {
                    this.router.navigate(['members', 'menu', 'home']);
                } else {
                    this.router.navigate(['login']);
                }
            });
            */

        });
/*
        this.platform.resume.subscribe(() => {
            alert(location.href);
                location.reload();
        });

*/
    }
}