import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RouterPage} from '../../../abstract/RouterPage';
import {ModalController} from '@ionic/angular';
import {UtilityService} from '../../../services/utility.service'
@Component({
  selector: 'app-message',
  templateUrl: './message.page.html',
  styleUrls: ['./message.page.scss'],
})
export class MessagePage extends RouterPage implements OnInit, AfterViewInit {


    title: string;
    subtitle: string;
    imgsource;
    buttonText: string;


    constructor(private modalController: ModalController,
                private route: ActivatedRoute,
                private utilityService: UtilityService,
                private router: Router) {

        super(router, route);

    }


    onEnter() {


    }
    ngOnInit() {
        console.log( " ====== imgsource " + this.imgsource );
        if ( this.utilityService.checkIfNull( this.imgsource ) || this.imgsource == "1"){
            this.imgsource = "/assets/img/success-icons/bank-blue-outline.svg";
        }else if ( this.imgsource == "2" ){
            this.imgsource = "/assets/img/success-icons/bank-blue-outline-error.svg";
        }
    }
    ngAfterViewInit() {


    }
    dismiss() {

        this.modalController.dismiss();
    }

}
