import {Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {environment, Facepay} from '../../environments/environment';
import * as encodeUrl from 'encodeurl';

import {Plugins} from '@capacitor/core';
import {FacepayService} from './facepay.service';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {FacepayClient} from '@facepay/api-client';
import * as moment from 'moment';
import {v4 as uuidv4} from 'uuid';

const {Browser} = Plugins;
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Person} from '@facepay/api-client/build/facepay/dao/person';
import {Purchase} from '@facepay/api-client/build/facepay/dao/purchase';
import {WorkingPage} from '../private/modals/working/working.page';
import {NavigationExtras} from '@angular/router';
import {Error} from 'tslint/lib/error';
import {Status} from '@facepay/api-client/build/facepay/dao/base';

import {PlaidService} from './plaid.service';
import {ServicesService} from './services.service';
import {GeneralFunService} from './generalFun.service';
import {Validator} from 'jsonschema';
import {TitleCasePipe, UpperCasePipe} from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class DwollaService extends BaseService {


    private domain = Facepay.domain;
    private url_base = Facepay.dwolla;


    private tokenizeUrl = Facepay.dwollaTokens + '/affiliate/tokenize';

    private extractUrl = Facepay.dwollaTokens + '/affiliate/extract';


    private coinbaseCheckout = this.url_base + '/coinbase/checkout';


    private customerDeactivateUrl = this.url_base + '/customer/deactivate';

    private classificationUrl = this.url_base + '/utils/classifications';
    private fundingAddUrl = this.url_base + '/funding/add';
    private fundingUrl = this.url_base + '/funding/link';
    private refundUrl = this.url_base + '/transfer/refund';
    private creditUrl = this.url_base + '/transfer/credit';
    private fundingBalanceUrl = this.url_base + '/funding/balance';

    private fundingMicrodepoositUrl = this.url_base + '/funding/micro-deposit-initiate';
    private fundingMicrodepoositVerifyUrl = this.url_base + '/funding/micro-deposit-verify';

    private amazonDisburseLink = this.url_base + '/amazon/disburse';
    private amazonCreategiftcardLink = this.url_base + '/amazon/creategiftcard';



    private fundingListUrl = this.url_base + '/funding/list';
    private fundingNameUrl = this.url_base + '/funding/name';
    private fundingRemoveUrl = this.url_base + '/funding/remove';
    private payloadUrl = this.url_base + '/transfer/payload';
    private signup2payUrl = this.url_base + '/signup2pay';
    private transferUrl = this.url_base + '/transfer/payment';
    private transferCancelUrl = this.url_base + '/transfer/cancel';
    private transferDescribeUrl = this.url_base + '/transfer/describe';
    private beneficialUrl = this.url_base + '/customer/beneficial-owners';
    private beneficialOwnershipUrl = this.url_base + '/customer/beneficial-ownership';

    private documentsListUrl = this.url_base + '/customer/documents-list';
    private beneficialListUrl = this.url_base + '/customer/beneficial-owners-list';
    private beneficialStatusUrl = this.url_base + '/customer/beneficial-owners-status';

    private customerDescribeUrl = this.url_base + '/customer/describe';
    private personalUnverifiedUrl = this.url_base + '/customer/personal/unverified';
    private personalVerifiedUrl = this.url_base + '/customer/personal/verified';
    private personalVerifiedRetryUrl = this.url_base + '/customer/personal/verified/retry';
    private verifiedUrl = this.url_base + '/customer/verified';
    private unverifiedUrl = this.url_base + '/customer/unverified';
    private retryUrl = this.url_base + '/customer/retry';

    private setupUrl = this.url_base + '/utils/setup';
    private setupGuestUrl = this.url_base + '/utils/setupGuest';

    private businessVerifiedSoleProprietorshipUrl = this.url_base + '/customer/business/verified/soleproprietorship';

    private documentUrl = this.url_base + '/customer/document';


    private customer_base_states = ['customer_submitted', 'customer_created'];

    private customer_states = ['customer_reverification_needed', 'customer_verified', 'customer_suspended', 'customer_activated', 'customer_deactivated',
        'customer_verification_document_needed', 'customer_verification_document_uploaded', 'customer_verification_document_failed', 'customer_verification_document_approved'];
    private funding_states = ['customer_funding_source_added', 'customer_funding_source_removed', 'customer_funding_source_verified', 'customer_funding_source_unverified', 'customer_funding_source_negative', 'customer_funding_source_updated'];

    private transfer_states = ['customer_transfer_submitted', 'customer_transfer_created', 'customer_transfer_cancelled', 'customer_transfer_failed', 'customer_transfer_completed', 'customer_bank_transfer_created', 'customer_bank_transfer_creation_failed', 'customer_bank_transfer_cancelled', 'customer_bank_transfer_failed', 'customer_bank_transfer_completed']
    private ach_states = ['customer_transfer_created', 'customer_transfer_cancelled', 'customer_transfer_failed', 'customer_transfer_completed', 'customer_bank_transfer_created', 'customer_bank_transfer_creation_failed', 'customer_bank_transfer_cancelled', 'customer_bank_transfer_failed', 'customer_bank_transfer_completed']




    private qrInsertUrl = Facepay.qr + '/qr/insert';
    private qrLookupUrl = Facepay.qr + '/qr/lookup';
    private qrDumpUrl = Facepay.qr+ '/qr/dump';

    constructor(
        private titlecase: TitleCasePipe,
        private uppercase: UpperCasePipe,
        private plaidService: PlaidService,
        private servicesService: ServicesService,
        private facepayService: FacepayService,
        private generalFunService: GeneralFunService,
        public storage: Storage, public httpClient: HttpClient
    ) {
        super(storage, httpClient);

    }

    public qrInsert(business, userUuid?, address? ) {



        const uuid = userUuid ? userUuid : uuidv4();

        const link = {
            address: address ? address : business.getAddress(),
            origin: business.getOption('origin.address') ?  business.getOption('origin.address') :   business.getAddress(),
            env: environment && environment.production ? 'prod' : 'dev',
            name: business.getName(),
            gravatar: business.getOption('gravatar') ?  business.getOption('gravatar') : 'https://login.facepay.io/assets/img/business-picture.svg',
            uuid: uuid

        }


        let query = {uuid: uuid, link: link}
        console.log(query)

        return new Promise((resolve, reject) => {

            fetch(this.qrInsertUrl,

                {
                    method: "post",
                    headers: {
                        "Content-type": "application/json",
                        "Accept": "application/json",
                        "Accept-Charset": "utf-8"
                    },
                    body: JSON.stringify(query)
                })
                .then(res => res.json()) // expecting a json response
                .then(json =>resolve(json))
                .catch(err=>reject(err));


        });

    }

    public qrLookup(uuid) {
        return new Promise((resolve, reject) => {

            fetch(this.qrLookupUrl,

            {
                method: "post",
                    headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json",
                    "Accept-Charset": "utf-8"
            },
                body: JSON.stringify(uuid)
            })
                .then(res => res.json()) // expecting a json response
                .then(json =>resolve(json))
                .catch(err=>reject(err));

        });



    }

    public qrDump() {
        return new Promise((resolve, reject) => {

            fetch(this.qrDumpUrl)

                .then(res => res.json()) // expecting a json response
                .then(json =>resolve(json))
                .catch(err=>reject(err));


        });

    }


    


    public checkout(charge) {
        return this.invoke(this.coinbaseCheckout, charge);
    }
    public classifications() {
        return this.invoke(this.classificationUrl);
    }

    public customerDeactivate(customer) {
        return this.invoke(this.customerDeactivateUrl, customer);
    }


    public addVerifiedCustomer(customer) {


        return new Promise((resolve, reject) => {


            if (customer.type == 'personal') {
                this.personalVerified(customer).then((result) => resolve(result)).catch((error) => reject(error));
            } else if (customer.type === 'business' && customer.businessType === 'soleProprietorship') {
                this.businessVerifiedSoleProprietorship(customer).then((result) => resolve(result)).catch((error) => reject(error));
            }

        });

    }


    public getCustomerSchemas() {


        // shemas are in jsonschema format
        const schemas: any = {
            'address': {
                'id': '/address',
                'type': 'object',
                'properties': {

                    'address1': {'type': 'string', description: 'Street', required: true},
                    'city': {'type': 'string', description: 'City', required: true},
                    'stateProvinceRegion': {
                        'type': 'string',
                        description: 'State',
                        minlength: '2',
                        maxlength: '2',
                        required: true,
                        pattern: '^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$'
                    },
                    'postalCode': {
                        'type': 'string',
                        description: 'Postal Code',
                        minlength: '5',
                        maxlength: '5',
                        required: true,
                        pattern: '^\\d{5}$'
                    },


                    'country': {'type': 'string', description: 'Country', required: true},
                },
                'additionalProperties': false

            },
            'controller': {
                'id': '/controller',
                'type': 'object',
                'properties': {

                    'firstName': {'type': 'string', description: 'First Name', required: true},
                    'lastName': {'type': 'string', description: 'Last Name', required: true},
                    'title': {'type': 'string', description: 'First Name', required: true},
                    'dateOfBirth': {'type': 'string', description: 'Birthday', 'format': 'date'},
                    'ssn': {

                        'oneOf': [
                            {
                                'type': 'string',
                                description: 'Social Security Number',
                                required: true,
                                'pattern': '^[0-9]{4}$'
                            },
                            {
                                'type': 'string',
                                description: 'Social Security Number',
                                required: true,
                                'pattern': '^[0-9]{3}-[0-9]{2}-[0-9]{4}$'
                            }]


                    },
                    'address': {'$ref': '/address'},

                    'additionalProperties': false


                }
            },

            'personal': {
                'id': '/personal',
                'type': 'object',
                'properties': {
                    'type': {'type': 'string', description: 'Type', required: true},
                    'email': {'type': 'string', description: 'Email', required: true},
                    'correlationId': {'type': 'string', description: 'CorrelationId', required: true},
                    'firstName': {'type': 'string', description: 'First Name', required: true},
                    'lastName': {'type': 'string', description: 'Last Name', required: true},
                    'dateOfBirth': {'type': 'string', description: 'Birthday', 'format': 'date'},
                    'address1': {'type': 'string', description: 'Street', required: true},
                    'city': {'type': 'string', description: 'City', required: true},

                    'state': {
                        'type': 'string',
                        description: 'State',
                        minlength: '2',
                        maxlength: '2',
                        required: true,
                        pattern: '^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$'
                    },
                    'postalCode': {
                        'type': 'string',
                        description: 'Postal Code',
                        minlength: '5',
                        maxlength: '5',
                        required: true,
                        pattern: '^\\d{5}$'
                    },

                    'ssn': {
                        'oneOf': [
                            {
                                'type': 'string',
                                description: 'Social Security Number',
                                required: true,
                                'pattern': '^[0-9]{4}$'
                            },
                            {
                                'type': 'string',
                                description: 'Social Security Number',
                                required: true,
                                'pattern': '^[0-9]{3}-[0-9]{2}-[0-9]{4}$'
                            }]
                    },
                    'additionalProperties': false
                }
            },
            'soleProprietorship': {
                'id': '/soleProprietorship',
                'type': 'object',
                'properties': {
                    'businessClassification': {'type': 'string', description: 'Industry', required: true},
                    'businessType': {'type': 'string', description: 'Business Type', required: true},
                    'businessName': {'type': 'string', description: 'Name', required: true},
                    'type': {'type': 'string', description: 'Type', required: true},
                    'email': {'type': 'string', description: 'Email', required: true},
                    'correlationId': {'type': 'string', description: 'CorrelationId', required: true},
                    'firstName': {'type': 'string', description: 'First Name', required: true},
                    'lastName': {'type': 'string', description: 'Last Name', required: true},
                    'dateOfBirth': {'type': 'string', description: 'Birthday', required: true, 'format': 'date'},
                    'address1': {'type': 'string', description: 'Street', required: true},
                    'city': {'type': 'string', description: 'City', required: true},
                    'state': {
                        'type': 'string',
                        description: 'State',
                        minlength: '2',
                        maxlength: '2',
                        required: true,
                        pattern: '^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$'
                    },
                    'postalCode': {
                        'type': 'string',
                        description: 'Postal Code',
                        minlength: '5',
                        maxlength: '5',
                        required: true,
                        pattern: '^\\d{5}$'
                    },
                    'ssn': {
                        'oneOf': [
                            {
                                'type': 'string',
                                description: 'Social Security Number',
                                required: true,
                                'pattern': '^[0-9]{4}$'
                            },
                            {
                                'type': 'string',
                                description: 'Social Security Number',
                                required: true,
                                'pattern': '^[0-9]{3}-[0-9]{2}-[0-9]{4}$'
                            }]
                    },
                    'additionalProperties': false
                },
            },
            'llc': {
                'id': '/llc',
                'type': 'object',
                'properties': {
                    'businessClassification': {'type': 'string', description: 'Industry', required: true},
                    'businessType': {'type': 'string', description: 'Business Type', required: true},
                    'businessName': {'type': 'string', description: 'Name', required: true},
                    'type': {'type': 'string', description: 'Type', required: true},
                    'email': {'type': 'string', description: 'Email', required: true},
                    'correlationId': {'type': 'string', description: 'CorrelationId', required: true},
                    'firstName': {'type': 'string', description: 'First Name', required: true},
                    'lastName': {'type': 'string', description: 'Last Name', required: true},
                    'address1': {'type': 'string', description: 'Street', required: true},
                    'city': {'type': 'string', description: 'City', required: true},
                    'state': {
                        'type': 'string',
                        description: 'State',
                        minlength: '2',
                        maxlength: '2',
                        required: true,
                        pattern: '^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$'
                    },
                    'postalCode': {
                        'type': 'string',
                        description: 'Postal Code',
                        minlength: '5',
                        maxlength: '5',
                        required: true,
                        pattern: '^\\d{5}$'
                    },
                    'controller': {'$ref': '/controller'},
                    'additionalProperties': false
                }
            }
        }

        schemas.corporation = schemas.llc;
        schemas.corporation.id = '/corporation'
        schemas.partnership = schemas.llc;
        schemas.partnership.id = '/partnership'

        return schemas;

    }

    public affiliateTokenize(data) {
        return this.invoke(this.tokenizeUrl, data);
    }

    public affilateExtract(token) {
        return this.invoke(this.extractUrl,{token: token});
    }


    public describe(customer) {
        return this.invoke(this.customerDescribeUrl, customer);
    }


    public setupAsAService(setupdata) {
        return this.invoke(this.setupUrl, setupdata);
    }
    public setupGuestAsAService(setupdata) {
        return this.invoke(this.setupGuestUrl, setupdata);
    }

    public verified(customer) {
        return this.invoke(this.verifiedUrl, customer);
    }

    public unverified(customer) {
        return this.invoke(this.unverifiedUrl, customer);
    }

    public retry(customer) {
        return this.invoke(this.retryUrl, customer);
    }

    public beneficial(customer) {
        return this.invoke(this.beneficialUrl, customer);
    }


    public beneficialOwnership(customer) {
        return this.invoke(this.beneficialOwnershipUrl, customer);
    }

    public documentsList(customer) {
        return this.invoke(this.documentsListUrl, customer);
    }


    public beneficialList(customer) {
        return this.invoke(this.beneficialListUrl, customer);
    }

    public beneficialStatus(customer) {
        return this.invoke(this.beneficialStatusUrl, customer);
    }

    public personalUnverified(customer) {
        return this.invoke(this.personalUnverifiedUrl, customer);
    }

    public personalVerified(customer) {
        return this.invoke(this.personalVerifiedUrl, customer);
    }

    public personalVerifiedRetry(customer) {
        return this.invoke(this.personalVerifiedRetryUrl, customer);
    }

    public businessVerifiedSoleProprietorship(customer) {
        return this.invoke(this.businessVerifiedSoleProprietorshipUrl, customer);
    }

    public fundingRouting(funding) {
        return this.invoke(this.fundingAddUrl, funding);
    }

    public funding(funding) {
        return this.invoke(this.fundingUrl, funding);
    }
    public credit(credit) {
        return this.invoke(this.creditUrl, credit);
    }
    public refund(refund) {
        return this.invoke(this.refundUrl, refund);
    }

    public fundingList(funding) {
        return this.invoke(this.fundingListUrl, funding);
    }

    public fundingName(funding) {
        return this.invoke(this.fundingNameUrl, funding);
    }

    public fundingBalance(funding) {
        return this.invoke(this.fundingBalanceUrl, funding);
    }

    public fundingRemove(funding) {
        return this.invoke(this.fundingRemoveUrl, funding);
    }

    public transfer(transfer) {
        return this.invoke(this.transferUrl, transfer);
    }

    public payload(transfer) {
        return this.invoke(this.payloadUrl, transfer);
    }
    public signup2pay(transfer, index?:number) {
        if(index)
          return this.invoke(this.signup2payUrl+'/'+index, transfer);
        return this.invoke(this.signup2payUrl, transfer);
    }

    public transferCancel(transfer) {
        return this.invoke(this.transferCancelUrl, transfer);
    }
    public transferDescribe(transfer) {
        return this.invoke(this.transferDescribeUrl, transfer);
    }
    public microdeposit(transfer) {
        return this.invoke(this.fundingMicrodepoositUrl, transfer);
    }
    public microdepositVerify(transfer) {
        return this.invoke(this.fundingMicrodepoositVerifyUrl, transfer);
    }
    public amazonCreategiftcard(transfer) {
        return this.invoke(this.amazonCreategiftcardLink, transfer);
    }
    public amazonDisburse(transfer) {
        return this.invoke(this.amazonDisburseLink, transfer);
    }


    public document(document) {
        return this.invoke(this.documentUrl, document);
    }


    public customerState(obj: Business | Person) {

        let state = this.state(obj, this.customer_states);

        if (!state) {
            state = this.state(obj, this.customer_base_states);
        }

        return state;


    }

    public fundingState(obj: Business | Person) {
        return this.state(obj, this.funding_states);
    }

    public transferState(obj: Purchase) {
        return this.state(obj, this.transfer_states);
    }

    public achState(obj: Purchase) {
        return this.state(obj, this.ach_states);
    }


    public customerEvents(obj: Business | Person) {
        return this.events(obj, this.customer_states);
    }

    public fundingEvents(obj: Business | Person) {
        return this.events(obj, this.funding_states);
    }

    public transferEvents(obj: Purchase) {
        return this.events(obj, this.transfer_states);
    }

    private state(obj: Business | Person | Purchase, allowed) {

        if (!obj || !obj.options) {
            return null
        }


        let eventsSorted = this.events(obj, allowed);


        let event = this.generalFunService.head(eventsSorted);

        return event;

    }


    private events(obj: Business | Person | Purchase, allowed) {

        if (!obj || !obj.options) {
            return null
        }

        const events = this.generalFunService.omitBy(obj.options, function (value, key) {
            return !key.startsWith('dwolla.event');
        })

        // console.dir(events);


        var eventsJson = this.generalFunService.map(events, function (p) {
            return JSON.parse(p);
        });

        // console.dir(eventsJson);
        let that = this;

        var eventsFiltered = this.generalFunService.filter(eventsJson, function (p) {
            return that.generalFunService.includes(allowed, p.topic);
        });

        // console.dir(eventsFiltered);


        //sort events most recent first
        let eventsSorted = this.generalFunService.orderBy(eventsFiltered, ['created'], ['desc']);


//        console.dir(eventsSorted);

//        console.dir(eventsSorted.length);
//        console.dir(eventsSorted[0]);

//        if ( eventsSorted ) {
//            eventsSorted = eventsSorted.reverse();
//        }

//        console.dir(eventsSorted);

        return eventsSorted;

    }


    async status(business: Business) {


        const result: any = {data: {}, type: null, status: null}


        const customerUrl = business.getOption('dwolla.customer.verified');

        if (!customerUrl) {
            return result
        }

        result.customerUrl = customerUrl;
        const customer: any = await this.describe({customerUrl: customerUrl})
        result.data.customer = customer;
        result.type = customer.type;
        result.businessType = customer.businessType;


        if (customer._links['verify-beneficial-owners']) {
            result['verify-beneficial-owners'] = true;

        } else {
            result['verify-beneficial-owners'] = false;
            const ver = await this.beneficialList({customerUrl: customerUrl})
            result.data['verify-beneficial-owners'] = ver;

        }

        if (customer._links['certify-beneficial-ownership']) {
            result['certify-beneficial-owners'] = true;

        } else {
            result['certify-beneficial-owners'] = false;
        }


        const docs = await this.documentsList({customerUrl: customerUrl})

        if (docs && Object.keys(docs).length === 0 && docs.constructor === Object) {
            result.docs = true;
            result.data.docs = docs;

        } else {
            result.docs = false;
        }

        switch (customer.businessType) {

            case'llc':
            case 'corporation':
            case 'partnership':
                result.businessType = customer.businessType;


        }


        result.status = customer.status;


        return result;


    }


    validateCustomer(type, customer): boolean {


        const schemas = this.getCustomerSchemas();

        const v = new Validator();

        v.addSchema(schemas.address, '/address');
        v.addSchema(schemas.controller, '/controller');

        const verrors = v.validate(customer, schemas[type])


        console.log(verrors.valid);
        console.log(verrors.errors);

        return verrors.valid;

    }


    setupToBeneficial(setupdata: any): any {


        let customer: any = {}
        customer.firstName = this.titlecase.transform(setupdata.ownerinfo.firstName);
        customer.lastName = this.titlecase.transform(setupdata.ownerinfo.lastName);
        customer.dateOfBirth = setupdata.ownerinfo.bday;
        customer.ssn = setupdata.ownerinfo.ssn;


        customer.address = {
            address1: this.titlecase.transform(setupdata.ownerinfo.street),
            city: this.titlecase.transform(setupdata.ownerinfo.city),
            stateProvinceRegion: this.uppercase.transform(setupdata.ownerinfo.state),
            postalCode: setupdata.ownerinfo.postalCode,
            country: 'US'
        }


        return customer;

    }


    setupToRetryCustomer(business: Business, user: any, setupdata: any): any {


        const customer = this.setupToCustomer(business, user, setupdata);

        if (setupdata.type && setupdata.type.type === 'personal') {
            delete customer.dateOfBirth
            customer.ssn = setupdata.ownerinfo.ssn
        } else {


            switch (setupdata.type.type) {
                case 'soleProprietorship':
                    delete customer.dateOfBirth
                    customer.ssn = setupdata.ownerinfo.ssn

                    break;
                default:

                    delete customer.controller.dateOfBirth
                    customer.controller.ssn = setupdata.ownerinfo.ssn
            }


        }

        return customer;

    }

    setupToCustomer(business: Business, user: any, setupdata: any): any {


        let email = user.email;
        if (email) {
            email = email.replace('@', '+' + uuidv4() + '@')
        }

        let customer: any = {
            email: email,
            correlationId: 'Business.' + business.getAddress() + '.' + uuidv4()
        }

        if (setupdata.type && setupdata.type.type === 'personal') {
            customer.type = 'personal';
            customer.firstName = this.titlecase.transform(setupdata.ownerinfo.firstName);
            customer.lastName = this.titlecase.transform(setupdata.ownerinfo.lastName);
            customer.dateOfBirth = setupdata.ownerinfo.bday;
            customer.ssn = setupdata.ownerinfo.ssn;//setupdata.ownerinfo.ssn.slice(-4);
            customer.address1 = this.titlecase.transform(setupdata.ownerinfo.street);
            customer.city = this.titlecase.transform(setupdata.ownerinfo.city);
            customer.state = this.uppercase.transform(setupdata.ownerinfo.state);
            customer.postalCode = setupdata.ownerinfo.postalCode

        } else {
            customer.type = 'business';
            customer.firstName = this.titlecase.transform(setupdata.ownerinfo.firstName);
            customer.lastName = this.titlecase.transform(setupdata.ownerinfo.lastName);
            customer.address1 = this.titlecase.transform(setupdata.businessinfo.street);
            customer.city = this.titlecase.transform(setupdata.businessinfo.city);
            customer.state = this.uppercase.transform(setupdata.businessinfo.state);
            customer.postalCode = setupdata.businessinfo.postalCode;
            customer.businessClassification = setupdata.businessinfo.industryClassification;
            customer.businessType = setupdata.type.type;
            customer.businessName = setupdata.businessinfo.name


            switch (setupdata.type.type) {
                case 'soleProprietorship':

                    customer.dateOfBirth = setupdata.ownerinfo.bday;
                    customer.ssn = setupdata.ownerinfo.ssn.slice(-4);

                    break;

                default:

                    customer.ein = setupdata.businessinfo.ein;
                    customer.controller = {
                        firstName: this.titlecase.transform(setupdata.ownerinfo.firstName),
                        lastName: this.titlecase.transform(setupdata.ownerinfo.lastName),
                        title: setupdata.ownerinfo.title,
                        dateOfBirth: setupdata.ownerinfo.bday,
                        ssn: setupdata.ownerinfo.ssn, //setupdata.ownerinfo.ssn.slice(-4),
                        address: {
                            address1: this.titlecase.transform(setupdata.ownerinfo.street),
                            city: this.titlecase.transform(setupdata.ownerinfo.city),
                            stateProvinceRegion: this.uppercase.transform(setupdata.ownerinfo.state),
                            postalCode: setupdata.ownerinfo.postalCode,
                            country: 'US'
                        }
                    }

            }


        }


        return customer;

    }


    private async asyncOptions(business: any, options: any) {


        const opt: any = {
            obj: {
                address: business.getAddress(),
                _reflection: business._reflection,
                iv: business.getOption('enc.iv')
            },
            options: options.options,
            enc: options.enc,
            fenc: options.fenc
        }

        this.servicesService.asyncOptions(opt);

    }

    public async addBank(business: any, setupdata: any, customerUrl: string, location: string ='destination') {

        return new Promise((resolve, reject) => {

            let asyncOptionName = `dwolla.funding.${location}`;
            console.log( " ====== asyncOptionName asyncOptionName " + asyncOptionName + " location = " + location );

            ;(async () => {
                const now = new Date();

                const uuidToken = setupdata.bankinfo.uuidToken;
                const account = setupdata.bankinfo.metadata.account;
                const account_id = account.id;
                const account_name = account.name;

                const token = {public_token: setupdata.bankinfo.public_token, account_id: account_id};

                const [accounts, e1] = await this.handle(this.plaidService.account(token));
                if (e1) {
                    reject(e1);
                    return;
                }
                console.log(accounts);


                this.asyncOptions(business, {
                    fenc: [
                        {option: 'store.plaid.accounts', value: accounts}

                    ]
                });


                const [p, e2] = await this.handle(this.plaidService.exchange(token));
                if (e2) {
                    reject(e2);
                    return;
                }
                console.log(p);



                const access_token = p.access_token;
                const processor_token = p.processor_token;


                const funding = {
                    name: account_name,
                    customerUrl: customerUrl,
                    plaidToken: processor_token
                }


                console.dir(funding);

                const [f, e3] = await this.handle(this.funding(funding));
                if (e3) {
                    reject(e3);
                    return;
                }
                console.dir(f);

                const fundingUrl = f.fundingUrl;

                console.dir(fundingUrl);


                this.asyncOptions(business, {
                    options: [
                        {option: asyncOptionName, value: encodeURI(fundingUrl)},
                    ],
                    fenc: [
                        {option: 'enc.token.uuid', value: uuidToken},
                        {option: 'enc.token', value: access_token},
                    ]
                });

                resolve(fundingUrl)
            })()

        })


    }


    //returns fundingUrl
    public async addManual(business: any, setupdata: any, customerUrl: string,location: string ='destination') {

        return new Promise((resolve, reject) => {


            ;(async () => {
                const now = new Date();

                const account = setupdata.bankinfo;

                const funding = {
                    customerUrl: customerUrl,
                    source: {
                        'routingNumber': account.routing,
                        'accountNumber': account.account,
                        'bankAccountType': account.type,
                        'name': account.name
                    }
                }

                console.dir("=== funding ", funding);

                const [f, e3] = await this.handle(this.fundingRouting(funding));
                if (e3) {
                    reject(e3);
                    return;
                }
                console.dir( "==== funding f", f);

                const fundingUrl = f.fundingUrl;

                console.dir("===== funding url" + fundingUrl);

                let asyncOptionName = `dwolla.funding.${location}`;
                this.asyncOptions(business, {
                    options: [
                        {option: asyncOptionName, value: encodeURI(fundingUrl)},
                    ]
                });

                resolve(fundingUrl)
            })()

        })


    }


    async submitBeneficialCustomer(business: Business, user: any, setupdata?: any) {

        return new Promise((resolve, reject) => {


            ;(async () => {
                const now = new Date();


                if (!setupdata) {
                    const esetup = business.getOption('enc.setup');
                    const fresult: any = await this.servicesService.fdecrypt(esetup);
                    setupdata = fresult.decrypted;
                }

                console.log('setupdata')
                console.dir(setupdata)

                const customerUrl = business.getOption('dwolla.customer.verified');


                const [desc, e3] = await this.handle(this.describe({customerUrl: customerUrl}));
                if (e3) {
                    reject()
                }
                console.dir(desc);


                const status = desc.status;

                if (status && status === 'suspended') {
                    reject()

                }


                if (desc && desc._links['verify-beneficial-owners']) {

                    const beneficial: any = {
                        customer: this.setupToBeneficial(setupdata),
                        customerUrl: customerUrl
                    }

                    console.dir(beneficial);


                    await this.handle(this.beneficial(beneficial))

                }

                resolve()


            })()


        })
    }


    async submitCertify(business: Business) {

        return new Promise((resolve, reject) => {


            ;(async () => {
                const now = new Date();


                const customerUrl = business.getOption('dwolla.customer.verified');


                const [desc, e3] = await this.handle(this.describe({customerUrl: customerUrl}));
                if (e3) {
                    reject()
                }
                console.dir(desc);


                const status = desc.status;

                if (status && status === 'suspended') {
                    reject()

                }


                if (desc && desc._links['certify-beneficial-ownership']) {

                    await this.handle(this.beneficialOwnership({customerUrl: customerUrl}))

                }

                resolve()


            })()


        })
    }



    async submitDocument(business: Business, customerUrl?) {

        return new Promise((resolve, reject) => {
            ;(async () => {

                const now = new Date();



                //because may be beneficial owner
                if ( ! customerUrl ) {
                    customerUrl = business.getOption('dwolla.customer.verified');
                }

                const license: any = await this.servicesService.documentRetrieve({bid: business.getAddress()});

                const licenseImage: string = license.base64;

                const document = {
                    customerUrl: customerUrl,
                    fileContents: licenseImage
                }


                console.dir(document);

                const result: any = await this.document(document)

                const documentUrl = result.documentUrl;


                this.asyncOptions(business, {
                    options: [{
                        'option': 'dwolla.document',
                        'value': encodeURI(documentUrl)
                    }]
                })

            })()


        })
    }
    async submitDocument2(business: Business, setupdata?: any) {

        return new Promise((resolve, reject) => {
            ;(async () => {

                const now = new Date();


                if (!setupdata) {
                    const esetup = business.getOption('enc.setup');
                    const fresult: any = await this.servicesService.fdecrypt(esetup);
                    setupdata = fresult.decrypted;
                }


                let customerUrl = business.getOption('dwolla.customer.verified');


                const document = {
                    customerUrl: customerUrl,
                    fileContents: setupdata.licenseinfo.image
                }


                console.dir(document);

                const result: any = await this.document(document)

                const documentUrl = result.documentUrl;


                this.asyncOptions(business, {
                    options: [{
                        'option': 'dwolla.document',
                        'value': encodeURI(documentUrl)
                    }]
                })

            })()


        })
    }

    async submitRetryCustomer(business: Business, user: any, setupdata?: any) {

        return new Promise((resolve, reject) => {


            ;(async () => {
                const now = new Date();


                if (!setupdata) {
                    const esetup = business.getOption('enc.setup');
                    const fresult: any = await this.servicesService.fdecrypt(esetup);
                    setupdata = fresult.decrypted;
                }

                const [ipv4, e1] = await this.handle(this.servicesService.whoami());
                if (e1) {
                    throw new Error('Count not fetch ip address')
                }


                setupdata.ipv4 = ipv4;

                const customer = this.setupToRetryCustomer(business, user, setupdata);

                console.dir(customer);

                //validate it

                if (!this.validateCustomer(setupdata.type.type, customer)) {

                    reject()
                }


                let customerUrl = business.getOption('dwolla.customer.verified');

                const retry: any = {
                    customer: customer,
                    customerUrl: customerUrl
                }

                console.dir(retry);


                const [c, e2] = await this.handle(this.personalVerifiedRetry(retry))

                if (e2) {

                    console.dir(e2);

                    const errors = e2.error.body._embedded.errors

                    console.dir(errors);
                    let invalid = 'Your application has some errors in the data. Please correct and resubmit. \n\n';

                    for (let i = 0; i < errors.length; i++) {
                        const e = errors[i];
                        console.error(e)
                        if (e.message) {
                            console.dir(e.message);
                            invalid += ' ' + e.message + ' '
                        }
                    }
                    reject()

                }

                const state = {

                    created: moment(now).toISOString(),  //has to be before dwolla so is first event
                    event: uuidv4(),
                    map: 'Submitted',
                    resource: null,
                    status: 'submitted',
                    topic: 'customer_retry_submitted',
                    updatedOn: +moment(),
                    source: 'facepay'

                }


                this.asyncOptions(business, {
                    options: [
                        {option: 'dwolla.event.' + state.event, value: JSON.stringify(state)},

                    ],
                    enc: [
                        {option: 'enc.dwolla.customer.retry.submitted', value: customer},
                    ]
                })

                resolve([customer, customerUrl]);


            })()


        })
    }


    async submitVerifiedCustomer(business: Business, user: any, setupdata?: any, testCustomerFirstName?: string) {

        return new Promise((resolve, reject) => {


            ;(async () => {
                const now = new Date();


                if (!setupdata) {
                    const esetup = business.getOption('enc.setup');
                    const fresult: any = await this.servicesService.fdecrypt(esetup);
                    setupdata = fresult.decrypted;
                }

                const [ipv4, e1] = await this.handle(this.servicesService.whoami());
                if (e1) {
                    throw new Error('Count not fetch ip address')
                }


                setupdata.ipv4 = ipv4;

                const customer = this.setupToCustomer(business, user, setupdata);

                console.dir(customer);

                if (testCustomerFirstName) {

                    customer.firstName = testCustomerFirstName;
                }


                //validate it

                if (!this.validateCustomer(setupdata.type.type, customer)) {

                    reject()
                }

                const [c, e2] = await this.handle(this.verified(customer))

                const customerUrl = c.customerUrl;
                if (e2) {

                    console.dir(e2);

                    const errors = e2.error.body._embedded.errors

                    console.dir(errors);
                    let invalid = 'Your application has some errors in the data. Please correct and resubmit. \n\n';

                    for (let i = 0; i < errors.length; i++) {
                        const e = errors[i];
                        console.error(e)
                        if (e.message) {
                            console.dir(e.message);
                            invalid += ' ' + e.message + ' '
                        }
                    }
                    reject()

                }

                const state = {

                    created: moment(now).toISOString(),  //has to be before dwolla so is first event
                    event: uuidv4(),
                    map: 'Submitted',
                    resource: null,
                    status: 'submitted',
                    topic: 'customer_submitted',
                    updatedOn: +moment(),
                    source: 'facepay'

                }


                this.asyncOptions(business, {
                    options: [
                        {option: 'dwolla.event.' + state.event, value: JSON.stringify(state)},

                    ]
                })


                this.asyncOptions(business, {
                    options: [
                        {
                            option: 'dwolla.correlationId',
                            value: JSON.stringify(customer.correlationId)
                        },
                        {option: 'dwolla.customer.verified', value: encodeURI(customerUrl)},

                    ],
                    enc: [
                        {option: 'enc.dwolla.customer.submitted', value: customer},
                    ]
                })

                resolve([customer, customerUrl]);


            })()


        })
    }


    async setup(business: Business, setupdata?: any) {


        try {


// test if already setup            if ( )


            if (!setupdata) {
                const esetup = business.getOption('enc.setup');
                const fresult: any = await this.servicesService.fdecrypt(esetup);
                setupdata = fresult.decrypted;
            }
console.dir(setupdata)


            const user = setupdata.user;

            const [[customer, customerUrl], ea] = await this.handle(this.submitVerifiedCustomer(business, user, setupdata));


            if (customer.businessName) {
                await this.facepayService.rename(business, customer.businessName)
            } else if ( customer.firstName && customer.lastName) {
                await this.facepayService.rename(business, `${customer.firstName} ${customer.lastName}`)
            }


            console.log("Customer Rename")

            console.dir(customer)

            if ( customer.firstName && customer.lastName) {
                await this.facepayService.renameUser(business, customer.firstName, customer.lastName)
            }


            let industry = 'Personal';

            if (setupdata && setupdata.businessinfo && setupdata.businessinfo.industryClassification) {

                switch (setupdata.businessinfo.industryClassification) {
                    case '9ed35a3c-7d6f-11e3-be28-5404a6144203':
                        industry = 'Accounting';
                        break;
                    case '9ed4b9cc-7d6f-11e3-940b-5404a6144203':
                        industry = 'Automotive';
                        break;
                    case '9ed3813c-7d6f-11e3-82cc-5404a6144203':
                        industry = 'Consulting';
                        break;
                    case '9ed41d70-7d6f-11e3-851b-5404a6144203':
                        industry = 'Dental';
                        break;
                    case '9ed444a5-7d6f-11e3-85d6-5404a6144203':
                        industry = 'Home Services';
                        break;
                    case '9ed3813d-7d6f-11e3-bd65-5404a6144203':
                        industry = 'Instructor';
                        break;
                    case '9ed46ba5-7d6f-11e3-b01c-5404a6144203':
                        industry = 'Landscaping';
                        break;
                    case '9ed46ba6-7d6f-11e3-ae95-5404a6144203':
                        industry = 'Legal Services';
                        break;
                    case '9ed46b9f-7d6f-11e3-96b0-5404a6144203':
                        industry = 'Lifestyle';
                        break;
                    case '9ed3f673-7d6f-11e3-adb1-5404a6144203':
                        industry = 'Restaurants';
                        break;
                    case '9ed444a1-7d6f-11e3-858e-5404a6144203':
                        industry = 'Other';
                        break;
                }
            }


            switch (setupdata.type.type) {
                case 'personal':
                case 'soleProprietorship':
                    this.facepayService.contact(business, setupdata.ownerinfo, industry).then(() => {
                    })
                    break;
                default:
                    this.facepayService.contact(business, setupdata.businessinfo, industry).then(() => {
                    })
                    break;
            }


            //if fails is still ok
            //nned to do it now since plaid token is time limited
            let bankAccountName = '';

            if (setupdata.bankinfo.instant) {


                const [fundingUrl, ea] = await this.handle(this.addBank(business, setupdata, customerUrl));
                bankAccountName = setupdata.bankinfo.metadata.institution
                /*

                    'plaid.link': JSON.stringify(setupdata.bankinfo.metadata)

                 */


            } else if (setupdata.bankinfo.manual) {

                const [fundingUrl, ea] = await this.handle(this.addManual(business, setupdata, customerUrl));

                bankAccountName = setupdata.bankinfo.name

            }

            const termsBusiness = {
                'business.address': business.getAddress(),
                'business.name': business.getName(),
                'auth0.user': JSON.stringify(user),
                'terms.acceptedOn': +moment(),
                'dwolla.acceptedTosOn': +moment(),
                'dwolla.acceptedPrivacyOn': +moment(),
                'dwolla.acceptedAuthorizatonOn': +moment(),
                'plaid.acceptedOn': +moment(),
                'facepay.acceptedTosOn': +moment(),
                'facepay.acceptedPrivacyOn': +moment(),
                'facepay.acceptedIp': JSON.stringify(setupdata.ipv4),
                'plaid.acceptedIp': JSON.stringify(setupdata.ipv4),
                'dwolla.acceptedIp': JSON.stringify(setupdata.ipv4),
                'facepay.acceptedUser': user.sub,
                'plaid.acceptedUser': user.sub,
                'dwolla.acceptedUser': user.sub,
                'dwolla.customer': JSON.stringify(customer),
                'plaid.institution': JSON.stringify(bankAccountName),

            };

            this.asyncOptions(business, {
                enc: [
                    {option: 'enc.terms', value: termsBusiness},
                ]
            })


            //wow - that is a lot

//            await this.facepayService.updateStatus(business, Status.PENDING)


        } catch (e) {

            console.log(e)

        } finally {

        }


    }


}


