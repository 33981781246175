import {Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Facepay} from '../../environments/environment';
import * as encodeUrl from 'encodeurl';

import {Plugins} from '@capacitor/core';
import {FacepayService} from './facepay.service';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Error} from 'tslint/lib/error';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';
import {ServicesService} from './services.service';

const {Browser} = Plugins;


@Injectable({
    providedIn: 'root'
})
export class PlaidService extends BaseService {


    private domain = Facepay.domain;
    private url_base = Facepay.plaid;


    private linkUrl = this.url_base + '/util/create_link_token';
    private accountUrl = this.url_base + '/account';

    private exchangeUrl = this.url_base + '/account/exchange_token';

    private balanceUrl = this.url_base + '/account/balance';


    // /account/exchange does nto have access_token
    // /account/exchange_token does have access_token

    private institutionUrl = this.url_base + '/util/institution';

    constructor(public storage: Storage, public httpClient: HttpClient, private services: ServicesService) {
        super(storage, httpClient);

    }


    public link(params) {
        return this.invoke(this.linkUrl, params);
    }


    public balance(token) {
        return this.invoke(this.balanceUrl, token);
    }

    public account(token) {
        return this.invoke(this.accountUrl, token);
    }
    public exchange(token) {
        return this.invoke(this.exchangeUrl, token);
    }

    public async balanceCheck(guest: Guest) {


        if (guest && guest.getPerson() && guest.getPerson().getOption('plaid.account')) {


            const acct = guest.getPerson().getOption('plaid.account');

            console.log(acct);
            const result: any = await this.services.fdecrypt(acct)

            const plaidData = result.decrypted;

            console.dir(plaidData);

            const result2: any = await this.balance({access_token: plaidData.access_token, account_ids: [plaidData.account_id]})

           if ( result2 && result2.length > 0 && result2[0] && result2[0].balances ) {
                let balances_available = 0;
                let balances = result2[0].balances;
                let bankCurrent = balances.current;
                let bankAvailable = balances.available;
                
                if ( !isNaN( Number( bankCurrent ) ) && !isNaN( Number( bankAvailable ) ) 
                        && Number( bankCurrent ) > Number( bankAvailable ) ){
                    balances_available = bankCurrent;
                }else if ( !isNaN( Number( bankAvailable ) ) ){
                    balances_available = bankAvailable;
                }else{
                    balances_available = bankCurrent;
                }
                
                return balances_available ; //result2[0].balances.available

            }

        }

        return -1; //unable to get


        /*
                    [result, error] = await that.services.handle(that.plaidService.balance({access_token: access_token, account_ids: [account_id]}));
            if (error) {
                throw new Error('Count not get plaid account')
            }
            let bal = result;
            console.log("bal");
            console.log(bal);



            const plaidData = {
                access_token: access_token,
                ach: ach,
                institution: this.metadata.institution,
                link: this.metadata.account,
                account_id: account_id,
                balances: balances
            }




         */
    }

    public institution(institution) {
        return this.invoke(this.institutionUrl, institution);
    }


}


