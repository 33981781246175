import {NgModule} from '@angular/core';
import {NoPreloading, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {Auth0BusinessGuard} from './guards/auth0business.guard';
import {Auth0CallbackComponent} from './public/auth0-callback/auth0-callback.component';
import {BusinessResolverService} from './resolvers/businessresolver.service';
import {Auth0GuestWelcomeGuard} from './guards/auth0guestwelcome.guard';
import {Auth0GuestPurchaseGuard} from './guards/auth0guestpurchase.guard';
import {Auth0GuestChargeGuard} from './guards/auth0guestcharge.guard';

import {Auth0adminGuard} from './guards/auth0admin.guard';
import {Auth0SignupGuard} from './guards/auth0signup.guard';
import {Auth0GuestWelcomeSignupGuard} from './guards/auth0guestwelcomesignup.guard';
import {Auth0PageModule} from './public/auth0/auth0.module';
import {Auth0GuestWelcomeSuccessGuard} from './guards/auth0guestwelcomesuccess.guard';

import {Auth0activateGuard} from './guards/auth0activate.guard';
import {Auth0SetupGuard} from './guards/auth0setup.guard';
import {Auth0SetupSignupGuard} from './guards/auth0setupsignup.guard';
import {Auth0claimGuard} from './guards/auth0claim.guard';
import {Auth0AffiliatePayGuard} from './guards/auth0affiliatepay.guard';
import {Auth0DesktopGuard} from './guards/auth0desktop.guard';
import {Auth0DesktopSignupGuard} from './guards/auth0desktopsignup.guard';
import {Auth0AffiliateAuthorizeGuard} from './guards/auth0affiliateauthorize.guard';
import {Auth0GuestWelcomeVerifyGuard} from './guards/auth0guestwelcomeverify.guard';
import {Auth0DesktopAcceptGuard} from './guards/auth0desktopaccept.guard';
import {Auth0GuestTexttoPayGuard } from './guards/auth0guesttexttopay.guard'
import {Auth0GuestCheckinGuard } from './guards/auth0guestcheckin.guard'
import {Auth0ExpressAIGuard } from './guards/auth0expressai.guard'


const routes: Routes = [
    {
        path: '',
        loadChildren: './public/start/start.module#StartPageModule',
        pathMatch: 'full'
    },

    

    {path: 'public/qr-creator',
        canActivate: [Auth0BusinessGuard], loadChildren: './public/qr-creator/qr-creator.module#QrCreatorPageModule'},
    {path: 'public/salesforce/activation', loadChildren: './public/salesforce/activation/activation.module#ActivationPageModule'},
    {path: 'public/salesforce/plaid', loadChildren: './public/salesforce/plaid/plaid.module#PlaidPageModule'},
    {path: 'public/salesforce/qr', loadChildren: './public/salesforce/qr/qr.module#QrPageModule'},

    {path: 'auth0', loadChildren: './public/auth0/auth0.module#Auth0PageModule'},
    {path: 'auth0-callback', component: Auth0CallbackComponent},
    {path: 'auth0-two-factor', loadChildren: './public/auth0-two-factor/auth0-two-factor.module#Auth0TwoFactorPageModule'},
    // {path: 'aa', loadChildren: './debit-add-start/debit-add-start.module#DebitAddStartPageModule'},
    {path: 'login', loadChildren: './public/start/start.module#StartPageModule'},

    // guest pages

   
    {
      path: 'members',
      canActivate: [Auth0BusinessGuard],
      loadChildren: './members/member-routing.module#MemberRoutingModule',
      /*resolve: {
          isLoaded: BusinessResolverService
      }*/

    },
    {
      path: 'charges',
      canActivate: [Auth0BusinessGuard],
      loadChildren: () => import('./charges/charges.module').then(m => m.ChargesPageModule),
      
      /*resolve: {
          isLoaded: BusinessResolverService
      }*/

    },
    {path: 'l/:hash', loadChildren: './public/l/l.module#LPageModule'},
    {path: 'qr/:uuid', loadChildren: './public/qr/qr.module#QrPageModule'},

    // {path: 'setup',
    //     loadChildren: './public/desktop/setup-login/setup-login.module#SetupLoginPageModule'},
    // {path: 'claim',
    //     loadChildren: './claim/home/home.module#HomePageModule'},
    // {path: 'claim-complete/:uuid',  canActivate: [Auth0claimGuard],
    //     loadChildren: './claim/complete/complete.module#CompletePageModule'},

    {path: 'public/desktop/setup',
         loadChildren: './public/desktop/setup-login/setup-login.module#SetupLoginPageModule'},
    {path: 'public/desktop/setup-page',         canActivate: [Auth0SetupGuard],
        loadChildren: './public/desktop/setup/setup.module#SetupPageModule'},
    {path: 'public/desktop/setup-signup',         canActivate: [Auth0SetupSignupGuard],
        loadChildren: './public/desktop/setup/setup.module#SetupPageModule'},


    {
      path: 'desktop/analytics',
      canActivate: [Auth0DesktopSignupGuard],
      loadChildren: () => import('./desktop/analytics/analytics.module').then(m => m.AnalyticsPageModule)
  },
    
    
    {
        path: 'desktop/accounting',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/accounting/accounting.module').then( m => m.AccountinPageModule)
    },
    {
      path: 'desktop/customers',
      canActivate: [Auth0DesktopGuard],
      loadChildren: () => import('./desktop/customers/customers.module').then( m => m.CustomersPageModule)
    },
    {
      path: 'desktop/customerdetail',
      canActivate: [Auth0DesktopGuard],
      loadChildren: () => import('./desktop/customerdetail/customerdetail.module').then( m => m.CustomerdetailPageModule)
    },
    {
      path: 'desktop/payments',
      canActivate: [Auth0DesktopGuard],
      loadChildren: () => import('./desktop/payments/payments.module').then( m => m.PaymentsPageModule)
    },
    {
        path: 'desktop/settings',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/settings/settings.module').then( m => m.SettingsPageModule)
    },
    {
        path: 'desktop/customerboost',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/customerboost/customerboost.module').then( m => m.CustomerBoostPageModule)
    },
    {
      path: 'desktop/text2pay',
      canActivate: [Auth0DesktopGuard],
      loadChildren: () => import('./desktop/text2pay/text2pay.module').then( m => m.Text2PayPageModule)
  },
  

    {path: 'public/eula', loadChildren: './public/eula/eula.module#EulaPageModule'},
    {path: 'public/eula-subscription', loadChildren: './public/eulasubscription/eulasubscription.module#EulaSubscriptionPageModule'},
    {path: 'public/privacy', loadChildren: './public/privacy/privacy.module#PrivacyPageModule'},
    {path: 'public/contact', loadChildren: './public/contact/contact.module#ContactPageModule'},
    {
        path: 'logout',
        loadChildren: './logout/logout.module#LogoutPageModule'
    },
    {
        path: 'logout-success', loadChildren: './logout-success/logout-success.module#LogoutSuccessPageModule'
    },
    
  {
    path: 'desktop/home',
        canActivate: [Auth0DesktopGuard],
        // loadChildren: () => import('./desktop/home/home.module').then(m => m.HomePageModule)
        loadChildren: () => import('./desktop/start/start.module').then( m => m.StartPageModule)
      },
// old one :)        
  {
    path: 'desktop/start',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'desktop/start/:status',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'desktop/advisorshare',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/advisorshare/advisorshare.module').then( m => m.AdvisorSharePageModule)
  },
  {
    path: 'desktop/subscriptions',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/subscriptions/subscriptions.module').then( m => m.SubscriptionsPageModule)
  },
  {
    path: 'desktop/setup/setup',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/setup/setup/setup.module').then( m => m.SetupPageModule)
  },
  {
    path: 'desktop/setup/pending',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/setup/pending/pending.module').then( m => m.PendingPageModule)
  },

    {
        path: 'desktop/setup/document',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/setup/document/document.module').then( m => m.DocumentPageModule)
    },

    {
        path: 'desktop/public/accept',
        canActivate: [Auth0DesktopAcceptGuard],
        loadChildren: () => import('./desktop/public/accept/accept.module').then( m => m.AcceptPageModule)
    },


   
  {
    path: 'down',
    loadChildren: () => import('./public/down/down.module').then( m => m.DownPageModule)
  },
  
  {
    path: 'business/charge',
    canActivate: [Auth0ExpressAIGuard],
    loadChildren: () => import('./desktop/direct/expressai/expressai.module').then( m => m.ExpressAIPageModule)
  }, 
  {
    path: 'direct/expressai',
    canActivate: [Auth0ExpressAIGuard],
    loadChildren: () => import('./desktop/direct/expressai/expressai.module').then( m => m.ExpressAIPageModule)
  }, 
  {
    path: 'guest/signup/updatedata',
    // canActivate: [Auth0GuestWelcomeGuard],
    loadChildren: () => import('./guest/signup/updatedata/updatedata.module').then( m => m.UpdatedataPageModule)
  },
  {
    path: 'business/:bid/guests/:gid/charge',
    canActivate: [Auth0ExpressAIGuard],
    loadChildren: () => import('./desktop/direct/expressai/expressai.module').then( m => m.ExpressAIPageModule)
  }, 
  {
    path: 'business/:bid/checkin',
    canActivate: [Auth0GuestCheckinGuard],
    loadChildren: () => import('./desktop/direct/checkin/checkin.module').then( m => m.CheckinPageModule)
  }, 
  {
    path: 'business/checkin',
    canActivate: [Auth0GuestCheckinGuard],
    loadChildren: () => import('./desktop/direct/checkin/checkin.module').then( m => m.CheckinPageModule)
  }, 

  {

    path: 'business/guest/chargesuccess',
    canActivate: [Auth0GuestChargeGuard],
    loadChildren: () => import('./desktop/direct/charge-success/charge-success.module').then( m => m.ChargeSuccessPageModule)
  }, 

  
  {
    path: 'business/texttopay',
    canActivate: [Auth0GuestTexttoPayGuard],
    loadChildren: () => import('./desktop/direct/texttopay/texttopay.module').then( m => m.TexttoPayPageModule)
  }, 
  {
    path: 'business/facepay',
    canActivate: [Auth0GuestTexttoPayGuard],
    loadChildren: () => import('./desktop/direct/texttopay/texttopay.module').then( m => m.TexttoPayPageModule)
  },   
  {
    path: 'desktop/direct/texttopay',
    canActivate: [Auth0GuestTexttoPayGuard],
    loadChildren: () => import('./desktop/direct/texttopay/texttopay.module').then( m => m.TexttoPayPageModule)
  }, 
  {

    path: 'desktop/direct/texttopay-success',
    canActivate: [Auth0GuestChargeGuard],
    loadChildren: () => import('./desktop/direct/texttopay-success/texttopay-success.module').then( m => m.TexttoPaySuccessPageModule)
    }, 

  {
    path: 'business/:bid/guests/:gid/charge',
    canActivate: [Auth0GuestChargeGuard],
    loadChildren: () => import('./desktop/direct/charge/charge.module').then( m => m.ChargePageModule)
  }, 
  {

    path: 'business/guest/chargesuccess',
    canActivate: [Auth0GuestChargeGuard],
    loadChildren: () => import('./desktop/direct/charge-success/charge-success.module').then( m => m.ChargeSuccessPageModule)
  }, 

   

	
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, enableTracing: false, onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
