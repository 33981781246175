import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import { Storage } from '@ionic/storage';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth0BusinessService} from './auth0/auth0business.service';
import {Auth0} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignupService extends BaseService {
    bid: any = ""; 
    gid: any = "";
    userFid;

    businessId;
    isPartner = false;
    dashboardName;
    loginEmployee;
    gravatar;

    amount: any = 0 ;

    currentUser: any;
    business: any ;
    guest:any;
    person:any;
    
    firstName: string = "" ;
    lastName: string = "" ;
    email: string = "" ;
    companyName: string = "" ;
    cell: string = "" ;
    phoneFormatted : string = "" ;
    address: string = "" ;
    zipCode: string = "" ;
    licensePlateNumber;
    yearMakeModel;
    vehicle;
    passcode: string = "" ;
    bankInfo: any;
    guestSetupData: any;

    //drop Off
    services = {};
    comments;
    oldpart = "no";

    /*
        
        true: new user
        false: exist user for new business 
    */
    
    ifNewPerson: boolean = false;
    isManual: boolean = false;

    //Customer detail page source
    customerPageSorce; //dashboard , customerlist



    paymentAmunt: any;
    paymentNote: any;
    paymentDate;

    businessName: string = "";
    businessgravatar: string = "";
    microdepositEnabled = false;

    note;	 
    data  ;
    ref;   
    xurl;  
    retUrl;
    cbUrl ;
    bitly ;
    updatebank ;
    dropoff;
    bgravatar;
    redeem;
    amazon;

    pickupcode;
    advisorName;
    //fill it in text to pay
    highlights;
    // parameter, true or other. 
    // If it is true, will open subscription page. 
    subscription;
    //record selected subscription. 
    selectedSubscription;
    //All subscription 
    subscriptionTerm;
    // The subscription page may be forward to 3 page:
    //      1. newuser. will forward to bank page. 
    //      2. authuser. will forward to auth page. 
    //      3. guestselected. come from thankyou page, forward to subscriptioin sucess page. 
    subscriptionPageSource;


    dashboardCurrentBusiness;
    dashboardLoginBusiness;

    source;
    callApiFailMessage;
    serviceAdvisorLabel;
    ignorePersonStatusCheck = false;

    
    constructor(
        public storage: Storage, 
        public httpClient: HttpClient, 
        private router: Router,
        public auth0Service: Auth0BusinessService,
        ) {
        super(storage, httpClient);

        let chooserPos = this.router.url.indexOf( "chooser" );
        if ( chooserPos < 0 ){
           // this.goLogin();
        }

        console.log( "===== signup service chooserPos  ",  chooserPos , "windows location = ", this.router.url , " , this business " , this.business );
    }

    goLogin() {
        this.router.navigate(['guest', 'signup', 'home'], { skipLocationChange: true });
        return;

      }

    //Based on requirement 01/20/2023, If purcdhase address = 0x050751dBf3d2Fc9614850E80410DE97E0f95BaFB, need set it as processed
    setDwollaTransferStatus( purchase ){
        if ( purchase.getAddress() === "0x050751dBf3d2Fc9614850E80410DE97E0f95BaFB" ){
            purchase.setOption( "dwolla.transfer.status", "processed" );
        }
    }  

}