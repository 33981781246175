import {Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Facepay} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {JsonConvert, OperationMode} from 'json2typescript';

import * as encodeUrl from 'encodeurl';

import {Plugins} from '@capacitor/core';
import {Observable, Observer} from 'rxjs';

const {Browser} = Plugins;


@Injectable({
    providedIn: 'root'
})
export class BaseService {


    private jsonConvert: JsonConvert = new JsonConvert();
    messageObservable: Observable<any>;
    messageObserver: Observer<any>;

    constructor(public storage: Storage, public httpClient: HttpClient) {


//        this.jsonConvert.operationMode = OperationMode.LOGGING; // print some debug data
        this.jsonConvert.ignorePrimitiveChecks = true; // don't allow assigning number to string etc.
        this.messageObservable = new Observable((observer: Observer<any>) => {
            this.messageObserver = observer;
        });
    }



    public handle = (promise) => {
        return promise
            .then(data => Promise.resolve([data, undefined]))
            .catch(error => Promise.resolve([undefined, error]));
    }



    public deleteInvoke(url) {

        return new Promise((resolve, reject) => {

            this.httpClient.delete(url, {observe: 'response'})
                .subscribe(resp => {

                    console.log(url + ' -> ' + resp.status);

                    resolve(resp.body)
                }, error => {
                    console.log(error);
                    reject(error)
                });
        });

    }


    public invoke(url, json?) {


        if (json) {
            console.dir(json);
        }

        /*

        headers: {
    'Authorization': 'Bearer' + authToken
  }

         */

        if (json) {
            return new Promise((resolve, reject) => {
                console.log( "==== base service invoke url ", url , " ; json ", json , " httpClient ", this.httpClient );

                this.httpClient.post(url, json, {observe: 'response'})
                    .subscribe(resp => {
                        console.log( "====== base service invoke result" +  url + ' -> ' , resp);

                        resolve(resp.body)
                    }, error => {
                        console.log( "====== base service invoke result" +  url + ' -> error --> ' , error);
                        reject(error)
                    })


            });


        } else {
            return new Promise((resolve, reject) => {
                console.log('get');

                this.httpClient.get(url, {observe: 'response'})
                    .subscribe(resp => {

                        console.log(url + ' -> ' + resp.status);

                        resolve(resp.body)
                    }, error => {
                        console.log(url + ' error -> ' + error.status);
                        console.log(error);
                        reject(error)
                    });
            })
        }
    }

    notify(msg, color = 'primary', loading = false, topic?) {


        try {

            if (this.messageObserver) {
                this.messageObserver.next({'message': msg, 'color': color, 'loading': loading, 'topic': topic});
            }
        } catch (e) {
            console.error(e)
        }


    }

    stringToFunction = function (str) {
        var arr = str.split('.');

        var fn = (window || this);
        for (var i = 0, len = arr.length; i < len; i++) {
            fn = fn[arr[i]];
        }

        if (typeof fn !== 'function') {
            throw new Error('function not found');
        }

        return fn;
    };

    set(key, token: any) {


        let promise = null;

        if (null != token && typeof token === 'object') {

            promise = this.storage.set(key, this.jsonConvert.serializeObject(token));
        } else {
            promise = this.storage.set(key, token);

        }

        return promise;

    }

    get(key, type?: any) {

        return new Promise((resolve, reject) => {
            this.notify('Checking cache');


            this.storage.get(key).then((result: any) => {


                if (result) {


                    /*

                    NOTE:  you have to use conmpression.  when the code gets obvuscated, the function names disappear.

                     */
                    if (null != type && type._reflection) {

                        const MyClass = this.stringToFunction(type._reflection);
                        const instance = new MyClass();


                        resolve(this.jsonConvert.deserializeObject(result, instance));
                    } else if (null != type) {

                        resolve(this.jsonConvert.deserializeObject(result, type));
                    } else {
                        resolve(result);
                    }

                } else {
                    reject(null)
                }


            }).catch((error) => {
                console.log(4);
                console.dir(error);
                reject(error)
            })
        })


    }

    remove(key) {
        return this.storage.remove(key);
    }

    clear() {
        return this.storage.clear();
    }


}