import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
// import {UtilityService} from './utility.service';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import { isNumber } from 'ionic-angular/umd/util/util';

@Injectable({
    providedIn: 'root'
})
export class GeneralFunService extends BaseService {
    constructor(
        public storage: Storage, 
        public httpClient: HttpClient, 
        // private utilityService: UtilityService,
        ) {
        super(  storage, httpClient );
    }

    sortBy(array, comparerArray, direction = 1) {

      array.sort( function( a, b){
        let ifCompare = true;
        let arrayPos = 0;
        while( ifCompare ){
          if ( comparerArray && Array.isArray( comparerArray ) && arrayPos < comparerArray.length ){
            let compare = comparerArray[arrayPos];
            arrayPos ++;
            if ( (typeof compare) === "function" ){
              if ( compare(a) > compare(b) ){
                  ifCompare = false;
                  return 1 * direction;
                }else if (compare(a) < compare(b)){
                  ifCompare = false;
                  return -1 * direction;
                }
            }else{
              if ( a[compare] > b[compare] ){
                ifCompare = false;
                return 1 * direction;
              }else if (a[compare] < b[compare]){
                ifCompare = false;
                return -1 * direction;
              }
            }
            
          }else{
            ifCompare = false;
            return 1;
          }
        }
          
      }) 
      
      return array
    }

    isNaN( num ){
      return Number.isNaN( num )      
    }
  
    orderBy(array, comparerArray, directionArr) {
  
      array.sort( function( a, b){
        let ifCompare = true;
        let arrayPos = 0;
        while( ifCompare ){
          if ( comparerArray && Array.isArray( comparerArray ) && arrayPos < comparerArray.length ){
            let compare = comparerArray[arrayPos];
            let direction = 1;
            if ( directionArr[arrayPos] === "desc" ){
              direction = -1;
            }
            arrayPos ++;
            if ( (typeof compare) === "function" ){
              if ( compare(a) > compare(b) ){
                  ifCompare = false;
                  return 1 * direction;
                }else if (compare(a) < compare(b)){
                  ifCompare = false;
                  return -1 * direction;
                }
            }else{
              if ( a[compare] > b[compare] ){
                ifCompare = false;
                return 1 * direction;
              }else if (a[compare] < b[compare]){
                ifCompare = false;
                return -1 * direction;
              }
            }
            
          }else{
            ifCompare = false;
            return 1;
          }
        }
          
      }) 
      
      return array
    }
  
    checkIfNull( value ){
      if (!value || value === null || value === undefined || value === "false" || value === "null" || value === "undefined") {                           
          return true;
      }else{
          return false;
      }     
    }
    
    cloneDeep( obj ){

        if ( this.checkIfNull( obj ) ){
          return obj;
        }
        let target ;
    
        if (  (typeof obj) === "object" ){
          let objType = Object.prototype.toString.call( obj ) 
          console.log( " objType " + objType );
          switch  (objType){
            case "[object Array]":
              target = [];
              Object.assign( target, obj );
              break;
            case "[object Date]":
              target = new Date();
              break;
            case "[object Object]":
              
              target = JSON.parse(JSON.stringify(obj));
              break;
            default:
              console.log( " no expect type");
              break;
          }
        }
    
        return target;
      }
    
      filter( obj, func ){
        if ( Array.isArray( obj ) ){
          return obj.filter( func );
        }else{
          return obj;
        }
    
      }
       
    
      includes( collect, obj ){
        let result = collect.includes( obj );
        return result;
      }
    
      head( collect ){
        if ( Array.isArray( collect )){
          if ( collect.length > 0 ){
            return collect[0];
          }
        }
        return null;
      }
      first( collect ){
        if ( Array.isArray( collect )){
          if ( collect.length > 0 ){
            return collect[0];
          }
        }
        return null;
      }
    
      isArray( obj ){
        if ( this.checkIfNull( obj ) ){
          return false;
        }
    
        return Array.isArray( obj );
    
      }
    
      isEmpty( obj ){
        if ( this.checkIfNull( obj ) ){
          return obj;
        }
    
        if (  (typeof obj) === "object" ){
          let objType = Object.prototype.toString.call( obj ) 
          console.log( " objType " + objType );
          switch  (objType){
            case "[object Array]":
              if ( obj.length <= 0 ){
                return true;
              }else{
                return false;
              }
              break;
            case "[object Date]":
              if ( obj.getTime() === 0 ){
                return true;
              }else{
                return false;
              }
              break;
            case "[object Object]":
              if ( Object.keys(obj).length == 0 ){
                return true;
              }else{
                return false;
              }
              break;
            default:
              console.log( " no expect type");
              break;
          }
        }
      }
    
      omitBy( obj, func ){
    
        if (  (typeof obj) === "object" ){
          let objType = Object.prototype.toString.call( obj ) 
          console.log( " objType " + objType );
          switch  (objType){
            case "[object Array]":
              let returnArray = [];
              returnArray = obj.filter( (key)=>{
                if ( (typeof obj[key]) === "object" ){
                  return this.omitBy(  obj[key], func );
                }else{
                  if ( !func( obj[key] ) ){
                    returnObj[key] = obj[key];
                    return obj[key];
                  }
                }
                
              } )
    
              console.log( " ==== omitBy returnArray ", returnArray  )
              return returnArray;
            case "[object Date]":
              if ( obj.getTime() === 0 ){
                return true;
              }else{
                return false;
              }
              break;
            case "[object Object]":
              let objKeys = Object.keys( obj );
    
              let returnObj = {};
              let keys = objKeys.filter( (key)=>{
                
    
                if ( !func(  obj[key], key ) ){
                  returnObj[key] = obj[key];
                  return obj[key];
                }
              } )
    
              console.log( " ==== omitBy result ", returnObj  )
              return returnObj;
              break;
            default:
              console.log( " no expect type");
              break;
          }
        }
      }
    
      map( obj, func ){
    
        if (  (typeof obj) === "object" ){
          let objType = Object.prototype.toString.call( obj ) 
          console.log( " objType " + objType );
          switch  (objType){
            case "[object Array]":
              let returnArray = [];
              returnArray = obj.map( (key)=>{
                if ( (typeof obj[key]) === "object" ){
                  return this.map(  obj[key], func );
                }else{
                  return func( obj[key] )              
                }            
              } )
    
              console.log( " ==== map returnArray ", returnArray  )
              return returnArray;
            case "[object Date]":
              if ( obj.getTime() === 0 ){
                return true;
              }else{
                return false;
              }
              break;
            case "[object Object]":
              let objKeys = Object.keys( obj );
    
              let returnObj = [];
              objKeys.map( (key)=>{
                returnObj.push(func(  obj[key] ) );
              } )
    
              console.log( " ==== map result ", returnObj  )
              return returnObj;
              break;
            default:
              console.log( " no expect type");
              break;
          }
        }
      }
}