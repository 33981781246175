import {Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Auth0, Facepay} from '../../environments/environment';
import {FacepayText} from '../../environments/environment';
const uuidv4 = require ("uuid/v4");

import {Plugins} from '@capacitor/core';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {Purchase} from '@facepay/api-client/build/facepay/dao/purchase';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TwilioService extends BaseService {


    private url_base = Facepay.twilio;

    private link_base = Facepay.link;

    private bitly_url = this.url_base + '/mms/bitly';
    private shorten_url = this.url_base + '/mms/shorten';
    private expand_url = this.link_base + '/mms/expand';
    private send_url = this.url_base + '/mms/send';
    private email_url = this.url_base + '/email/send';
    private txt180_url = this.url_base + '/mms/txt180';
    private create_url = this.url_base + '/2fa/create';
    private verify_url = this.url_base + '/2fa/verify';

    constructor(public storage: Storage, public httpClient: HttpClient) {
        super(storage, httpClient);

    }


    public txt180(cell, msg, obj?) {


        return this.invoke(this.txt180_url, {cell: cell, msg: msg})


    }


    public send(sms) {

        return this.invoke(this.send_url, sms)

    }


    public bitly(url) {

        return this.invoke(this.bitly_url, url)

    }


    public email(email) {

        return this.invoke(this.email_url, email)

    }

    public shorten(url) {

        return this.invoke(this.shorten_url, {url: url})

    }

    public expand(hash) {

//        return this.invoke(this.expand_url, {hash: hash})


        return new Promise((resolve, reject) => {
            function status(response) {

                console.log(response)
                if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(new Error(response.statusText))
                }
            }

            function json(response) {
                console.log(response)
                return response.json()
            }


            console.log(this.expand_url);

            fetch(this.expand_url,{
                method: "POST",
                body: JSON.stringify({hash: hash}),
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "same-origin"
            })
                .then(status)
                .then(json)
                .then(function(data) {
                    console.log('Request succeeded with JSON response', data);
                    resolve(data)
                }).catch(function(error) {
                console.log('Request failed', error);
                reject()
            });

        });
    }


    public create(phone) {
        return this.invoke(this.create_url, {'phone': phone, sid: '2fa'})

    }

    public verify(phone, code) {


        return this.invoke(this.verify_url, {'phone': phone, 'code': code, sid: '2fa'});
    }


    public sendWelcome(business: Business, guest: Guest) {

        console.log('twilio welcome');


        return new Promise(resolve => {


            if (!guest || !guest.getPerson() || !guest.getPerson().getCell()) {
                console.log('twilio guest not found');
                resolve()
            } else {
                console.log('twilio guest ok');

                //guest/business/:bid/guests/:gid/welcome

                let url = FacepayText.welcomeUrl;

                url = url.replace(':bid', business.getAddress())
                url = url.replace(':gid', guest.getAddress())

                console.log(url)

                //url= ' https://api-ssl.bitly.com/v3/shorten?access_token=' + Facepay.bitlyToken + '&format=txt&uri=' + encodeURIComponent(url);
                console.log(url)


                this.invoke(this.bitly_url, {url: url})
                    .then((result: any) => {

                        const shortenUrl = result.url.url;

                        console.dir(shortenUrl)

                        console.log('shortenUrl: ' + shortenUrl)
                        const sms = {
                            cell: guest.getPerson().getCell(), //data.phone,
                            msg: 'Welcome to ' + business.getName() + '. Please setup your Facepay account to begin service ' + shortenUrl,
                            sid: 'welcome'
                        }

                        this.send(sms).then((result) => resolve(result));

                    })

            }

        })
    }


    public generatePurchaseOnly(business: Business, guest: Guest, purchase: Purchase, isPicture) {


        return new Promise(resolve => {


            console.log('twilio guest ok');

            //guest/business/:bid/guests/:gid/welcome

            let url = FacepayText.purchaseOnlyUrl;
            console.log(url)

            url = url.replace(':bid', business.getAddress())
            url = url.replace(':gid', guest.getAddress())
            url = url.replace(':pid', purchase.getAddress())


            url = url.replace(':bname', encodeURIComponent(business.getName()))
            url = url.replace(':bgravatar', encodeURIComponent(business.getOption('gravatar')))
            url = url.replace(':cell', encodeURIComponent(guest.getPerson().getCell()))
            url = url.replace(':amount', encodeURIComponent(purchase.getAmount()))
            url = url.replace(':date', encodeURIComponent(purchase.getDate()))
            url = url.replace(':isPicture', isPicture)

            console.log(url)


            this.invoke(this.bitly_url, {url: url})
                .then((result: any) => {

                    const shortenUrl = result.url.url;

                    resolve(shortenUrl);
                });


        });

    }

    public generateWelcomeOnly(business: Business, cell: string, uuid: string) {

        console.log('twilio welcome');


        return new Promise(resolve => {


            console.log('twilio guest ok');

            //guest/business/:bid/guests/:gid/welcome

            let url = FacepayText.welcomeOnlyUrl;
            console.log(url)

            url = url.replace(':bid', business.getAddress())
            url = url.replace(':uuid', encodeURIComponent(uuid))
            url = url.replace(':bname', encodeURIComponent(business.getName()))
            url = url.replace(':bgravatar', encodeURIComponent(business.getOption('gravatar')))
            url = url.replace(':cell', encodeURIComponent(cell))

            console.log(url)


            this.invoke(this.bitly_url, {url: url})
                .then((result: any) => {

                    const shortenUrl = result.url.url;

                    resolve(shortenUrl);
                });


        });
    }


    public generateWelcome(business: Business, uuid: string, cell: string, guest: Guest) {

        console.log('twilio welcome');


        return new Promise(resolve => {


            if (!guest || !guest.getPerson() || !guest.getPerson().getCell()) {
                console.log('twilio guest not found');
                resolve()
            } else {
                console.log('twilio guest ok');

                //guest/business/:bid/guests/:gid/welcome

                let url = FacepayText.welcomeUrl;

                url = url.replace(':bid', business.getAddress())
                url = url.replace(':uuid', encodeURIComponent(uuid))
                url = url.replace(':bname', encodeURIComponent(business.getName()))
                url = url.replace(':bgravatar', encodeURIComponent(business.getOption('gravatar')))
                url = url.replace(':cell', encodeURIComponent(cell))

                console.log(url)

                //url= ' https://api-ssl.bitly.com/v3/shorten?access_token=' + Facepay.bitlyToken + '&format=txt&uri=' + encodeURIComponent(url);
                console.log(url)


                this.invoke(this.bitly_url, {url: url})
                    .then((result: any) => {

                        const shortenUrl = result.url.url;

                        console.dir(shortenUrl)

                        console.log('shortenUrl: ' + shortenUrl)
                        const sms = {
                            cell: guest.getPerson().getCell(), //data.phone,
                            msg: 'Welcome to ' + business.getName() + '. Please setup your Facepay account to begin service ' + shortenUrl + '. Thank you!',
                            sid: 'welcome'
                        }

                        resolve(sms);
                    });

            }

        });
    }

    public sendReceipt(business: Business, guest: Guest, purchase: Purchase) {


        return new Promise(resolve => {


            if (!guest || !guest.getPerson() || !guest.getPerson().getCell()) {
                resolve()
            } else {
                //guest/business/:bid/guests/:gid/welcome

                let url = FacepayText.purchaseUrl;

                url = url.replace(':bid', business.getAddress())
                url = url.replace(':gid', guest.getAddress())
                url = url.replace(':pid', purchase.getAddress())

                console.log(url)

                //url = ' https://api-ssl.bitly.com/v3/shorten?access_token=' + Facepay.bitlyToken + '&format=txt&uri=' + encodeURIComponent(url);
                console.log(url)


                this.invoke(this.bitly_url, {url: url})
                    .then((result: any) => {

                        const shortenUrl = result.url.url;


                        console.log('shortenUrl: ' + shortenUrl)
                        const sms = {
                            cell: guest.getPerson().getCell(), //data.phone,
                            msg: 'Receipt from ' + business.getName() + '. Thank you! ' + shortenUrl,

//                        msg: "Thank you for coming to " + business.getName() + ". To see more details, click here " + shortenUrl,
                            sid: 'receipt'
                        }

                        this.send(sms).then((result) => resolve(result));

                    })

            }

        })
    }


}


