import {AlertController, Platform} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {BehaviorSubject} from 'rxjs';
import {JsonConvert, OperationMode} from 'json2typescript';


import {parseFullName} from 'parse-full-name';


import {FacepayClient} from '@facepay/api-client';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {Employee} from '@facepay/api-client/build/facepay/dao/employee';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';
import {Purchase} from '@facepay/api-client/build/facepay/dao/purchase';
import {Person} from '@facepay/api-client/build/facepay/dao/person';


import {environment, Facepay, FacepayText} from '../../environments/environment';
import {Auth0BusinessService} from './auth0/auth0business.service';
import {ActivatedRouteSnapshot, NavigationExtras, RouterStateSnapshot} from '@angular/router';


import {Observable, Observer, Subscription, fromEvent} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {ServicesService} from './services.service';
import {Status} from '@facepay/api-client/build/facepay/dao/base';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {TwilioService} from './twilio.service';
import {v4 as uuidv4} from 'uuid';
import {Error} from 'tslint/lib/error';


@Injectable({
    providedIn: 'root'
})
export class FacepayService extends BaseService {


    public tokenKey = 'facepay-token';
    public addressKey = 'facepay-address';
    public businessKey = 'facepay-business';


    public businessImage: string | ArrayBuffer;
    public employeeImage: string | ArrayBuffer;

    private email: any;


    private qrInsertUrl = Facepay.qr + '/qr/insert';
    private qrLookupUrl = Facepay.qr + '/qr/lookup';
    private qrDumpUrl = Facepay.qr + '/qr/dump';

    public qrInsert(business, userUuid?, address?) {


        const uuid = userUuid ? userUuid : uuidv4();

        const link = {
            address: address ? address : business.getAddress(),
            origin: business.getOption('origin.address') ? business.getOption('origin.address') : business.getAddress(),
            env: environment && environment.production ? 'prod' : 'dev',
            name: business.getName(),
            gravatar: business.getOption('gravatar') ? business.getOption('gravatar') : 'https://login.facepay.io/assets/img/business-picture.svg',
            uuid: uuid

        }


        let query = {uuid: uuid, link: link}
        console.log(query)

        return new Promise((resolve, reject) => {

            fetch(this.qrInsertUrl,

                {
                    method: 'post',
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'Accept-Charset': 'utf-8'
                    },
                    body: JSON.stringify(query)
                })
                .then(res => res.json()) // expecting a json response
                .then(json => resolve(json))
                .catch(err => reject(err));


        });

    }

    public qrLookup(uuid) {
        return new Promise((resolve, reject) => {

            fetch(this.qrLookupUrl,

                {
                    method: 'post',
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'Accept-Charset': 'utf-8'
                    },
                    body: JSON.stringify(uuid)
                })
                .then(res => res.json()) // expecting a json response
                .then(json => resolve(json))
                .catch(err => reject(err));

        });


    }

    constructor(public storage: Storage, private plt: Platform,
                private twilioService: TwilioService,
                private auth0Service: Auth0BusinessService,
                public alertCtrl: AlertController,
                private services: ServicesService, public httpClient: HttpClient
    ) {
        super(storage, httpClient);

    }


    client() {
        return new FacepayClient(Facepay.api_direct);
    }

    clientPromise(): Promise<FacepayClient> {

        return new Promise((resolve, reject) => {


            const tokenSub = this.auth0Service.getTokenSilently$().subscribe((token) => {

                tokenSub.unsubscribe();

                resolve(new FacepayClient(Facepay.api, token, 'api'));

            });

        });


    }

    updateStatus(business: Business, status: Status) {


        return new Promise((resolve, reject) => {

            ;(async () => {


                let b = new Business()

                b.setAddress(business.getAddress())
                b.setStatus(status)

                const client: FacepayClient = await this.clientPromise();

                await client.sync(b);


                if (environment.production) {
                    try {




                        //Notify the business
                        if (status === Status.ACTIVE) {


                            let url = FacepayText.welcomeQrLinkUrl;

                            url = url.replace(':uuid', business.getAddress())


                            const shortResults: any = await this.twilioService.bitly({url: url})


                            const shortenUrl = shortResults.url.url;

                            console.log(shortenUrl);


                            const ownerCell = business.employees[0].getPerson().getCell()

                            //const qr = await login

                            const msg1 = 'Your account is verified! Welcome to the Facepay family. Login at ' + Facepay.domain + '.';

                            const msg2 = 'Your welcome kit is being mailed to you. In the meantime, this is a sample text you can share with your customers:';
                            const msg3 = `${business.getName()} is now Contactless!  Please setup before your next visit here ${shortenUrl}. See you soon`;

                            await this.twilioService.txt180(ownerCell, msg1)
                            setTimeout(() => {
                                this.twilioService.txt180(ownerCell, msg2).then(() => {
                                })
                            }, 3000);
                            setTimeout(() => {
                                this.twilioService.txt180(ownerCell, msg3).then(() => {
                                })
                            }, 6000);


                        }


                        const email = {
                            to: 'setup@facepay.io',
                            subject: `${business.getName()} status changed to ${status}`,
                            msg: `${business.getName()} address is ${business.getAddress()}`,
                        }

                        await this.twilioService.email(email)

                        let cellNumbers = ['+14082098386', '+19259808012', '+16028329601']
                        const msg = `${business.getName()} status changed to ${status}`


                        for (let i = 0; i < cellNumbers.length; i++) {
                            const cell = cellNumbers[i];
                            await this.twilioService.txt180(cell, msg)
                        }

                        //Yan only wants pending

                        if (status === Status.PENDING) {

                            cellNumbers = ['+14082282189']
                            for (let i = 0; i < cellNumbers.length; i++) {
                                const cell = cellNumbers[i];
                                await this.twilioService.txt180(cell, msg)
                            }

                        }


                        await this.services.salesforceSync({business: {address: b.getAddress()}})


                    } catch (e) {
                        console.log(e)
                    }
                }


                resolve();

            })()

        });


    }


    contact(business, info, industry) {

        return new Promise((resolve, reject) => {


            let b = new Business()

            b.setAddress(business.getAddress())
            b.setStreet(info.street)
            b.setCity(info.city)
            b.setState(info.state)
            b.setZip(info.postalCode)
            b.setOption('industry', industry)

            this.clientPromise().then((client: FacepayClient) => {

                client.sync(b).then((business) => {

                    console.dir(business)
                    resolve();


                })

            });

        });


    }



    renameUser(business, first, last) {


        return new Promise((resolve, reject) => {


            if (!business || !business.employee) {
                resolve()
            } else {

                const person = business.employees[0].getPerson()


                console.log("Before includes")

                if (person.getFirstName().includes('@') || person.getLastName().includes('@')) {


                    const p = new Person({address: person.getAddress()});
                    p.setFirstName(first)
                    p.setLastName(last)


                    ;(async () => {

                        const _client = await this.clientPromise();
                        await _client.sync(p)

                        console.log("After sync")

                        resolve();

                    })()

                } else {
                    resolve();
                }


            }


        });


    }


    rename(business, name) {


        return new Promise((resolve, reject) => {


            let b = new Business()

            b.setAddress(business.getAddress())
            b.setName(name)

            this.clientPromise().then((client: FacepayClient) => {

                client.sync(b).then((business) => {

                    console.dir(business)
                    resolve();


                })

            });

        });


    }


    findUser(user) {


        return new Promise(async (resolve, reject) => {

            if (!user) {
                reject()
            } else {


                let uid = user.sub;
                if (uid.startsWith('amazon')) {
                    uid = 'amazon|' + user.email;
                }

                let p = new Person();
                p.setMid(uid);
                p.setFid(uid);

                this.clientPromise().then((client: FacepayClient) => {
                    client.findPerson(p)
                        .then((person: Person) => {
                            resolve(person);
                        }).catch((err) => {
                        console.log(err)
                        reject(err);
                    });
                });
            }
        })
    }

    findUserInBusiness(user, business) {


        return new Promise(async (resolve, reject) => {
            if (!business || !user) return [null, null];


            if (business.guests) {

                for (let j = 0; j < business.guests.length; j++) {

                    const guest = business.guests[j];

                    if (!guest || !guest.getPerson()) {
                        continue;
                    }

                    const mid = guest.getPerson().getMid();

                    let uid = user.sub;
                    if (uid.startsWith('amazon')) {
                        uid = 'amazon|' + user.email;
                    }

                    if (uid && mid && uid === mid) {
                        resolve([guest, business])
                    }
                }
                resolve([null, null]);
            }
        })
    }


    findByQuickbooksUser(uid) {


        console.log('Find: ' + uid);


        let e = new Employee();
        e.setMid(uid);
        e.setFid(uid);

        console.log(e.constructor.name);

        return this.client().find(e)

    }


    public textCodeLog(business, cell, log) {


        return new Promise((resolve, reject) => {

            const options = [
                {
                    option: 'twilio.event.' + uuidv4(),
                    value: JSON.stringify({
                        sentOn: +moment(),
                        cell: cell,
                        msg: '2fa',
                        status: log
                    })
                },

            ]

            this.addBusinessOptions(business, options).then((business: Business) => {
                console.dir(business)

                resolve(business);
            }).catch((err) => {
                console.log(err)
                reject(err);

            })


        })

    }


    public addPurchaseOptions(business: Business, guest: Guest, purchase: Purchase, options) {


        return new Promise((resolve, reject) => {


            const pUpdate = new Purchase();
            pUpdate.setAddress(purchase.getAddress())


            console.log('Sync Purchase Options: ' + JSON.stringify(options));
            options.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                pUpdate.setOption(option.option, option.value)
            });


            console.log('Update:' + JSON.stringify(pUpdate));

            this.clientPromise().then((client: FacepayClient) => {
                client.sync(pUpdate)
                    .then((purchase: Purchase) => {
                        console.log('Completed:' + JSON.stringify(purchase));
                        resolve(purchase);
                    }).catch((err) => {
                    console.log(err)
                    reject(err);
                });
            });
        });

    }


    public addGuestOptions(business: Business, guest: Guest, user: any, options) {


        return new Promise((resolve, reject) => {

            //replace with new guest
            //delete invitation
            //


            const name = parseFullName(user.name);

            const person = {
                cell: guest.getPerson().getCell(),
                firstName: name.first,
                lastName: name.last,
                email: user.email

            }

            const pNew = new Person(person);
            pNew.setOption('auth0.user', JSON.stringify(user))
            pNew.setOption('gravatar', user.picture)


            let gNew = new Guest({person: new Person(person)})
            gNew.setOption('invitation.acceptedOn', +moment())
            gNew.setOption('invitation.from', guest.getAddress())
            gNew.setOption('auth0.user', JSON.stringify(user))
            gNew.setOption('gravatar', user.picture)


            console.log('Sync Guest Options: ' + JSON.stringify(options));
            options.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                gNew.setOption(option.option, option.value)
            });


            let gUpdate = guest;
            gUpdate.setAddress(guest.getAddress())
            gUpdate.setOption('invitation.status', 'accepted')

            console.log('Update:' + JSON.stringify(gUpdate));


            this.clientPromise().then((client: FacepayClient) => {
                client.business.set(business).create(gNew)
                    .then((guestCreated: Guest) => {
                        console.log('Completed Guest:' + JSON.stringify(guestCreated));

                        gUpdate.setOption('invitation.to', guestCreated.getAddress())

                        client.ofBusiness(business).sync(gUpdate).then((g: Guest) => {

                            console.log('Accepted Guest:' + JSON.stringify(g));

                            resolve(guestCreated);


                        }).catch((err) => {
                            console.log(err)
                            reject(err);


                        })
                    }).catch((err) => {
                    console.log(err)
                    reject(err);

                });
                ;
            });

        });

    }

    public newGuest(business: Business, customer: any, uid: string) {


        let p = new Person();
        p.setFirstName(customer.firstName)
        p.setLastName(customer.lastName)
        p.setCell(customer.cell)
        p.setEmail(customer.email)

        //critical for person lookup later
        p.setMid(uid);
        p.setFid(uid);


        const gNew = new Guest({person: p})

        gNew.setOption('invitation.acceptedOn', +moment())
        gNew.setOption('invitation.from', '0x0')
        gNew.setStatus(Status.ACTIVE);


        return new Promise((resolve, reject) => {

            this.clientPromise().then((client: FacepayClient) => {
                client.business.set(business).create(gNew).then((newG: Guest) => {
                    console.dir(newG)
                    resolve(newG)
                }).catch((err) => {
                    console.log(err)
                    reject(err);
                })

            })
        })


    }

    public addGuestField( business: Business, guest: Guest, guestFields ){
        return new Promise((resolve, reject) => {

            let gUpdate = new Guest( );
            gUpdate.setAddress(guest.getAddress())

            guestFields.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                gUpdate.setFieldValue(option.option, option.value)
            });

            console.log('==== addGuestField guestFields:' , guestFields, ", gUpdate ", gUpdate );

            this.clientPromise().then((client: FacepayClient) => {
                client.ofBusiness(business).sync(gUpdate).then((g: Guest) => {

                    console.log('==== addGuestField Guest:' , g );
    
                    resolve(g);
    
                }).catch((err) => {
                    console.log(err)
                    reject(err);
                })
    
            });
           

        })
    }

    public addGuestPersonOptions(business: Business, guest: Guest, user: any, cell: string, guestOptions, personOptions) {


        return new Promise((resolve, reject) => {

            //replace with new guest
            //delete invitation
            //


            console.log('########');
            console.dir(user);

            const name = parseFullName(user.name);

            let person = {
                cell: cell,
                firstName: name.first,
                lastName: name.last,
                email: user.email

            }


            const pNew = new Person(person);
            pNew.setOption('auth0.user', JSON.stringify(user))
            pNew.setOption('gravatar', user.picture)
            console.log('Sync Person Options: ' + JSON.stringify(personOptions));
            personOptions.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                pNew.setOption(option.option, option.value)
            });


            let gNew = new Guest({person: pNew})
            gNew.setOption('invitation.acceptedOn', +moment())
            gNew.setOption('invitation.from', guest ? guest.getAddress() : '0x0')
            gNew.setOption('auth0.user', JSON.stringify(user))
            gNew.setOption('gravatar', user.picture)
            gNew.setStatus(Status.ACTIVE);

            console.log('Sync Guest Options: ' + JSON.stringify(guestOptions));
            guestOptions.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                gNew.setOption(option.option, option.value)
            });

            this.clientPromise().then((client: FacepayClient) => {

                client.business.set(business).create(gNew)
                    .then((guestCreated: Guest) => {
                        console.log('Completed Guest:' + JSON.stringify(guestCreated));


                        if (guest && !guest.getAddress().startsWith('0x0')) {

                            let gUpdate = guest;
                            gUpdate.setAddress(guest.getAddress())
                            gUpdate.setOption('invitation.status', 'accepted')
                            gUpdate.setOption('invitation.to', guestCreated.getAddress())
                            gUpdate.setStatus(Status.DELETED);

                            console.log('Update:' + JSON.stringify(gUpdate));


                            client.ofBusiness(business).sync(gUpdate).then((g: Guest) => {

                                console.log('Accepted Guest:' + JSON.stringify(g));

                                resolve(guestCreated);


                            }).catch((err) => {
                                console.log(err)
                                reject(err);


                            })
                        } else {
                            resolve(guestCreated);
                        }
                    }).catch((err) => {
                    console.log(err)
                    reject(err);

                });
                ;
            });

        });

    }

    public addPersonOptions(business: Business, person: Person, options) {


        return new Promise((resolve, reject) => {

            let pUpdate = new Person({address: person.getAddress()});

            console.log('Sync Person Options: ' + JSON.stringify(options));
            options.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                pUpdate.setOption(option.option, option.value)
            });


            console.log('Update:' + JSON.stringify(pUpdate));

            this.clientPromise().then((client: FacepayClient) => {
                client.sync(pUpdate)
                    .then((finalPerson: Person) => {
                        console.log('Completed:');
                        console.dir(finalPerson)
                        resolve(finalPerson);
                    }).catch((err) => {
                    console.log(err)
                    reject(err);
                });
            });
        });

    }


    public addOptions(obj: any, options) {


        return new Promise((resolve, reject) => {


            let objUpdate: any;
            const address = obj.getAddress();


            console.log(address)
            console.log(obj._reflection)
            console.dir(options)

            switch (obj._reflection) {
                case 'Business':
                    objUpdate = new Business({address: address}) as Business;
                    break;
                case 'Person':
                    objUpdate = new Person({address: address}) as Person;
                    break;
                case 'Purchase':
                    objUpdate = new Purchase({address: address}) as Purchase;
                    break;
                case 'Guest':
                    objUpdate = new Guest({address: address}) as Guest;
                    break;
            }


            console.log('Sync  Options: ' + JSON.stringify(options));
            options.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                objUpdate.setOption(option.option, option.value)
            });


            console.log('Original ')
            console.dir(obj)

            console.log('To Update ')
            console.dir(objUpdate)

            this.clientPromise().then((client: FacepayClient) => {
                client.sync(objUpdate as typeof objUpdate)
                    .then((newObj: typeof objUpdate) => {
                        console.log('Completed Update')
                        console.dir(newObj)

                        resolve(newObj);
                    }).catch((err) => {
                    console.log(err)
                    reject(err);
                });
            });
        });
    }


    public addBusinessOptions(business: Business, options) {


        return new Promise((resolve, reject) => {

            const bUpdate = new Business();
            bUpdate.setAddress(business.getAddress())
            bUpdate.setName(business.getName())
            bUpdate.setMid(business.getMid())
            bUpdate.setFid(business.getFid())


            console.log('Sync Business Options: ' + JSON.stringify(options));
            options.forEach(function (option) {
                console.log(option.option + ': ' + option.value);
                bUpdate.setOption(option.option, option.value)
            });


            console.log('Original Business')
            console.dir(business)

            console.log('To Update Business')
            console.dir(bUpdate)

            this.clientPromise().then((client: FacepayClient) => {
                client.sync(bUpdate)
                    .then((newBusiness: Business) => {
                        console.log('Completed Business')
                        console.dir(newBusiness)

                        resolve(newBusiness);
                    }).catch((err) => {
                    console.log(err)
                    reject(err);
                });
            });
        });
    }


    resolve(): Promise<Business> {
        

        return new Promise((resolve, reject) => {


//            this.remove(this.businessKey).then(() => {


            this.get(this.businessKey, Business).then((business: Business) => {


                console.log('business is in cache');

                console.dir(business);


                resolve(business as Business);

            }).catch((err: any) => {


                console.log('error business not in cache');

                console.log(JSON.stringify(err));
                this.cache().then((business: Business) => {

                    resolve(business);

                }).catch((err: any) => {
                    reject();
                });


            });


        })


        //       });


    }


    refresh(): Promise<Business> {
        

        return new Promise((resolve, reject) => {


//            this.remove(this.businessKey).then(() => {


            this.remove(this.businessKey).then(() => {

                this.cache().then((business: Business) => {

                    resolve(business);

                }).catch((err: any) => {
                    reject();
                });


            });


        })


    }


    private cache(): Promise<Business> {
        

        return new Promise((resolve, reject) => {
            this.notify('Authenticating account');


            this.cacheCore().then((business) => {
                resolve(business)
            }).catch((err: any) => {
                console.dir(err);
                reject();
            });

            /*


            this.auth0Service.get(this.auth0Service.deviceAdmin).then((isAdmin) => {


                if (isAdmin) {


                    this.auth0Service.get(this.auth0Service.deviceAdminBusiness).then((business) => {


                        this.clientPromise().then((client: FacepayClient) => {


                            this.load(client, business).then((business: Business) => {


                                this.set(this.businessKey, business).then(() => {
                                    resolve(business);

                                })


                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });


                        }).catch((err: any) => {
                            console.dir(err);
                            reject();
                        });

                    });


                } else {

                    this.cacheCore().then((business) => {
                        resolve(business)
                    }).catch((err: any) => {
                        console.dir(err);
                        reject();
                    });


                }
            }).catch((err: any) => {

                this.cacheCore().then((business) => {
                    resolve(business)
                }).catch((err: any) => {
                    console.dir(err);
                    reject();
                });

            });*/


        });

    }


    cacheCore(): Promise<Business> {
        

        return new Promise((resolve, reject) => {


            const userSub = this.auth0Service.getUser$().subscribe((user) => {


                userSub.unsubscribe();
                console.log(" == facepay service cacheCore = ", user);

                console.dir(user);

                let uid = user.sub;

                if (user.sub.startsWith('amazon')) {
                    uid = 'amazon|' + user.email;
                }


                let e = new Employee();
                e.setMid(uid);
                e.setFid(uid);
                console.log(" == facepay service cacheCore Employee = ", e);

                this.clientPromise().then((client: FacepayClient) => {

                    console.dir(e);

                    client.find(e).then((business: Business) => {

                        console.log(" == facepay service cacheCore business = ", business);


                        if (0x0 === parseInt(business.address, 16)) {

                            console.log('business is new');


                            this.create(client, user).then((business: Business) => {

                                this.load(client, business).then((business: Business) => {


                                    this.set(this.businessKey, business).then(() => {
                                        resolve(business);

                                    })


                                }).catch((err: any) => {
                                    console.dir(err);
                                    reject();
                                });

                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });


                        } else {

                            // business.address = "0x66d2f9470EE7c2B68b5a5Ee0a309189f71a40ede"
                            console.log('loading business ', business);

                            this.load(client, business).then((business: Business) => {


                                console.log('business loaded ', business);
                                this.set(this.businessKey, business).then(() => {
                                    resolve(business);

                                })

                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });


                        }


                    }).catch((err: any) => {


                        console.log('business is new');
                        this.create(client, user).then((business: Business) => {


                            this.load(client, business).then((business: Business) => {
                                this.set(this.businessKey, business).then(() => {
                                    resolve(business);

                                })

                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });

                        }).catch((err: any) => {
                            console.dir(err);
                            reject();
                        });

                    });


                }).catch((err: any) => {

                    console.dir(err);
                    reject();
                });

            });


        });

    }


    lastLogin(): Promise<boolean> {


        return new Promise((resolve, reject) => {

            const userSub = this.auth0Service.getUser$().subscribe((user) => {


                userSub.unsubscribe();

                console.dir(user);

                let uid = user.sub;

                if (user.sub.startsWith('amazon')) {
                    uid = 'amazon|' + user.email;
                }


                let e = new Employee();
                e.setMid(uid);
                e.setFid(uid);

                this.clientPromise().then((client: FacepayClient) => {


                    client.find(e).then((business: Business) => {


                        if (0x0 !== parseInt(business.address, 16)) {


                            console.dir(business);
                            const employee = business.employees[0];

                            employee.setLastLoginDate(+moment() + '');

                            client.sync(employee).then((employee) => {


                                console.dir(employee);
                                resolve()
                            })


                        }


                    });

                });


            });

        });

    }

    ruthere(): Promise<string | false> {
        

        return new Promise((resolve, reject) => {

            const userSub = this.auth0Service.getUser$().subscribe((user) => {


                userSub.unsubscribe();

                console.dir(user);

                let uid = user.sub;


                if (user.sub.startsWith('amazon')) {
                    uid = 'amazon|' + user.email;
                }


                let e = new Employee();
                e.setMid(uid);
                e.setFid(uid);

                this.clientPromise().then((client: FacepayClient) => {


                    client.find(e).then((business: Business) => {


                        if (0x0 === parseInt(business.address, 16)) {

                            resolve(false)

                        } else {
                            //resolve(true)
                            resolve(business.address as string);
                        }


                    }).catch((err: any) => {

                        resolve(false);

                    });


                }).catch((err: any) => {

                    resolve(false);
                });

            });


        });

    }

    firstUse(): Promise<Business> {
        

        return new Promise((resolve, reject) => {

            this.notify('Authenticating account');

            const userSub = this.auth0Service.getUser$().subscribe((user) => {


                userSub.unsubscribe();

                console.dir(user);

                let uid = user.sub;

                if (user.sub.startsWith('amazon')) {
                    uid = 'amazon|' + user.email;
                }


                let e = new Employee();
                e.setMid(uid);
                e.setFid(uid);

                this.clientPromise().then((client: FacepayClient) => {

                    console.dir(e);


                    client.find(e).then((business: Business) => {


                        console.log('success ws visit!');
                        console.log('new business address is: ' + business.address);
                        console.log('new business name is: ' + business.getName());

                        console.dir(business);


                        if (0x0 === parseInt(business.address, 16)) {

                            console.log('business is new');


                            this.create(client, user).then((business: Business) => {

                                resolve(business);


                            }).catch((err: any) => {
                                reject(err)
                                console.dir(err);
                            });


                        } else {
                            resolve(business)
                        }


                    }).catch((err: any) => {


                        console.log('business is new');
                        this.create(client, user).then((business: Business) => {

                            resolve(business);

                        }).catch((err: any) => {

                            reject(err)
                            console.dir(err);
                        });

                    });


                }).catch((err: any) => {

                    reject(err)
                    console.dir(err);
                });

            });


        });

    }

    getEmail() {


        return new Promise((resolve, reject) => {

            const that = this;

            const modal = async () => {
                const modal: any = await this.alertCtrl.create({
                    header: 'Enter email address for sending payment receipts. Thank you!',
                    inputs: [
                        {
                            placeholder: 'Email',
                            type: 'text',
                            name: 'email'
                        },
                    ],
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: data => {
                                console.log('Cancel clicked');
                                throw new Error('No email provided')
                            }
                        },
                        {
                            text: 'Save',
                            handler: data => {

                                if (!data.email) {
                                    reject();
                                } else {
                                    resolve(data.email);
                                }


                            }
                        }
                    ],
                    cssClass: 'alert-box'
                });
                return modal;
            }


            modal().then((modal) => {
                modal.present();
            });
        });

    }


    private create(client: FacepayClient, user: any): Promise<Business> {
        

        return new Promise((resolve, reject) => {


            this.notify('First use. Making new account', 'primary', false, 'business');

//            this.clear().then(() => {


            /*

            {
  "sub": "google-oauth2|104357025704374739105",
  "given_name": "Mark",
  "family_name": "Hale",
  "nickname": "mark.hale",
  "name": "Mark Hale",
  "picture": "https://lh3.googleusercontent.com/a-/AAuE7mDDsBIuJJllwlhhlKAQ9QtXLIUgiA9wwLHexILI",
  "locale": "en",
  "updated_at": "2019-09-18T18:37:36.097Z"
}


{
  "sub": "amazon|amzn1.account.AH5CEB6VXMMHCTKZYOBVVALY37WA",
  "nickname": "mark.hale",
  "name": "Mark A. Hale",
  "picture": "https://s.gravatar.com/avatar/1bfe426449c7d4cf18bdb96d3479f6d7?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fma.png",
  "updated_at": "2019-09-18T18:38:27.640Z"
}


{
  "sub": "oauth2|Quickbooks|32e5dae9-de86-4ade-a9ca-5484c61e0d82",
  "given_name": "Mark",
  "family_name": "Hale",
  "nickname": "mark.hale",
  "name": "mark.hale@gmail.com",
  "picture": "https://s.gravatar.com/avatar/1bfe426449c7d4cf18bdb96d3479f6d7?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fma.png",
  "updated_at": "2019-09-18T18:58:00.578Z",
  "email": "mark.hale@gmail.com",
  "email_verified": true
}


             */


            this.get(this.auth0Service.deviceCell).then((cell) => {


                console.log('CELL2:' + cell);
                this.services.iv().then((result: any) => {

                    const iv = result.iv;

                    console.log('*** IV: ' + iv);



                            ;(async () => {


                        let ipv4 = '0.0.0.0';

                                let [result1, error1] = await this.services.handle(this.services.whoami());
                                if (error1) {
                                    console.log('Could not get IP address')
                                } else {
                                    ipv4 = result1;
                                }

                                console.dir(ipv4);

                                let name = user.name;

                                let uid = user.sub;

                                if (user.sub.startsWith('amazon')) {
                                    uid = 'amazon|' + user.email;
                                }


                                let email = user.email;


                                if (user.sub.startsWith('oauth2|wechat')) {


                                    name = user.nickname;
                                    try {
                                        email = await this.getEmail();


                                    } catch (e) {
                                        console.log(e);
                                    }

                                }


                                let business = new Business({name: name});
                                business.setMid(uid);
                                business.setFid(uid);
                                business.setOption('language', 'en_US')
                                business.setOption('sso', user.sub)
                                business.setOption('text.optout', false)
                                business.setOption('gravatar', user.picture)
                                business.setOption('enc.iv', iv)
                                business.setOption('registration.ip4', ipv4)
                                business.setRegistrationDate(+moment() + '')
                                business.setStatus(Status.SETUP)


                                this.notify('Creating your merchant account', 'primary', true, 'business');

                                client.create(business)
                                    .then((business: Business) => {
                                        console.log('success ws visit!');
                                        console.log('new business address is: ' + business.address);
                                        console.log('new business name is: ' + business.getName());


                                        console.log('setup email sent')

                                        this.qrInsert(business)
                                            .then((qrresult: any) => {


                                                business.setOption('qr.uuid', qrresult.uuid)


                                                client.sync(business)
                                                    .then((business: Business) => {


                                                        this.qrInsert(business, business.getAddress()).then((result) => {
                                                            console.log(result);
                                                        })


                                                        this.notify('Making you the account owner', 'primary', true, 'user');


                                                        this.services.encrypt(iv, user).then((result: any) => {

                                                            console.log(result);
                                                            console.dir(result);

                                                            const person = {
                                                                firstName: name,
                                                                lastName: name,
                                                                email: email,
                                                                cell: cell
                                                            }


                                                            let p = new Person(person);
                                                            //p.setCell(cell)
                                                            p.setOption('text.optout', false)
                                                            p.setOption('gravatar', user.picture)
                                                            p.setOption('auth0.user', JSON.stringify(user));
                                                            p.setOption('enc.auth0.user', result.encrypted);


                                                            let e = new Employee({person: p});
                                                            e.setMid(uid);
                                                            e.setFid(uid);
                                                            e.setOption('sso', user.sub)
                                                            e.setOption('text.optout', false)
                                                            e.setOption('gravatar', user.picture)
                                                            e.setOption('auth0.user', JSON.stringify(user));
                                                            e.setOption('enc.auth0.user', result.encrypted);


                                                            client.business.set(business).create(e)
                                                                .then((employee: Employee) => {

                                                                    //this triggers setup email
                                                                    this.updateStatus(business, Status.SETUP).then(() => {

                                                                        resolve(business);


                                                                    })

                                                                }).catch(function (err) {
                                                                console.log(err);
                                                                console.log('failed to visit ws!');
                                                                reject();
                                                            });
                                                        });
                                                    });

                                            })
                                    }).catch(function (err) {
                                    console.log(err);
                                    console.log('failed to visit ws!');
                                    reject();
                                });

                            })();


                });


            }).catch((err) => {
                reject();
                console.log(err)
            });


        })


    }

    public  getDownloadJsonFile(business: Business, jsonFileName:string ) {
        return new Promise((resolve, reject) => {
            let client = this.client();
            let cellNos: any = '';
            client.business.set(business).download(business, jsonFileName).then(( sentContent:any ) => {
                let customers = [];
                if(sentContent != null &&  sentContent != undefined && sentContent != '')
                    customers = sentContent;
                console.log('business download customer records: ' + customers.length, customers );

                resolve(customers); ;
            }).catch((err) => {
                console.log(err)
                reject(err);
            });;
        });
    }

    public addBusinessJson(business: Business, jsonFileName:string, jsonFileArray) {
        return new Promise((resolve, reject) => {

            console.dir(business)
            const bUpdate = new Business();
            bUpdate.setAddress(business.getAddress())

            try{
                console.log( " ===== addBusinessJson business = ", bUpdate, " jsonFileName = " + jsonFileName + " jsonFileArray = " , JSON.stringify(jsonFileArray) )
                let client = this.client();
                client.business.set(bUpdate).upload( bUpdate, JSON.stringify(jsonFileArray),jsonFileName ).then( ( result:any ) => {
                    bUpdate.setOption( jsonFileName, true );
                    client.sync(bUpdate)
                        .then((newBusiness: Business) => {
                            console.log('==========addBusinessJson Completed Business')
                            console.dir(newBusiness)
                            resolve(newBusiness);
                        }).catch((err) => {
                        console.log(err)
                        reject(err);
                    });
                }).catch((err) => {
                    console.log(err)
                    reject(err);
                });

            }catch (e) {
                console.log( "==== addBusinessJson upload fail  = "  );
                console.log(e);
                return false;
            }
        });
    }

    public load(client: FacepayClient, business): Promise<Business> {
        

        return new Promise((resolve, reject) => {

            this.notify('Securing account on private blockchain', 'success');

            client.business.set(business).load().then((business) => {

//                console.dir(business, {depth: null, colors: true});

                this.set(this.businessKey, business).then(() => {

                    this.set(this.addressKey, business.address).then(() => {

                        resolve(business);

                    });
                });
            }).catch((err: any) => {
                reject();
            });


        });

    }

    refreshGuest(): Promise<Business> {
        

        return new Promise((resolve, reject) => {


            const userSub = this.auth0Service.getUser$().subscribe((user) => {


                userSub.unsubscribe();
                console.log(" == facepay service cacheCore = ", user);

                console.dir(user);

                let uid = user.sub;

                if (user.sub.startsWith('amazon')) {
                    uid = 'amazon|' + user.email;
                }


                let e = new Employee();
                e.setMid(uid);
                e.setFid(uid);
                console.log(" == facepay service cacheCore Employee = ", e);

                this.clientPromise().then((client: FacepayClient) => {

                    console.dir(e);

                    client.find(e).then((business: Business) => {

                        console.log(" == facepay service cacheCore business = ", business);


                        if (0x0 === parseInt(business.address, 16)) {

                            console.log('business is new');


                            this.create(client, user).then((business: Business) => {

                                this.load(client, business).then((business: Business) => {


                                    this.set(this.businessKey, business).then(() => {
                                        resolve(business);

                                    })


                                }).catch((err: any) => {
                                    console.dir(err);
                                    reject();
                                });

                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });


                        } else {

                            // business.address = "0x66d2f9470EE7c2B68b5a5Ee0a309189f71a40ede"
                            console.log('loading business ', business);

                            this.loadGuestOnly(client, business).then((business: Business) => {


                                console.log('business loaded ', business);
                                this.set(this.businessKey, business).then(() => {
                                    resolve(business);

                                })

                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });


                        }


                    }).catch((err: any) => {


                        console.log('business is new');
                        this.create(client, user).then((business: Business) => {


                            this.load(client, business).then((business: Business) => {
                                this.set(this.businessKey, business).then(() => {
                                    resolve(business);

                                })

                            }).catch((err: any) => {
                                console.dir(err);
                                reject();
                            });

                        }).catch((err: any) => {
                            console.dir(err);
                            reject();
                        });

                    });


                }).catch((err: any) => {

                    console.dir(err);
                    reject();
                });

            });


        });

    }

    public loadGuestOnly(client: FacepayClient, business): Promise<Business> {
        

        return new Promise((resolve, reject) => {

            this.notify('Securing account on private blockchain', 'success');

            client.business.set(business).loadWithGuestsOnly().then((business) => {

//                console.dir(business, {depth: null, colors: true});

                this.set(this.businessKey, business).then(() => {

                    this.set(this.addressKey, business.address).then(() => {

                        resolve(business);

                    });
                });
            }).catch((err: any) => {
                reject();
            });


        });

    }
}


