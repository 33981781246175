import {Injectable} from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {from, of, Observable, BehaviorSubject, combineLatest, throwError} from 'rxjs';
import {of as observableOf} from 'rxjs';
import {tap, catchError, concatMap, shareReplay} from 'rxjs/operators';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Auth0} from '../../../environments/environment';
import {Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {JsonConvert, OperationMode} from 'json2typescript';

const queryString = require('query-string');


var url = require('url');

@Injectable({
    providedIn: 'root'
})
export abstract class Auth0BusinessService {


    public deviceAuth = 'auth0-device-verified';
    public deviceCell = 'auth0-device-cell';
    public deviceRemember = 'auth0-device-remember';
    public deviceAdmin = 'auth0-device-admin';
    public deviceAdminBusiness = 'auth0-device-admin-business';


    clear() {


        return new Promise((resolve, reject) => {


            this.remove(this.deviceAuth).then(() => {
            }).finally(() => {
                this.remove(this.deviceCell).then(() => {
                }).finally(() => {
                    this.remove(this.deviceRemember).then(() => {
                    }).finally(() => {
                        this.remove(this.deviceAdmin).then(() => {
                        }).finally(() => {
                            this.remove(this.deviceAdminBusiness).then(() => {
                            }).finally(() => {
                                resolve();

                            });
                        });
                    });
                });
            });

        });


    }


    private jsonConvert: JsonConvert = new JsonConvert();

    constructor(public storage: Storage, private route: ActivatedRoute, private router: Router) {


        this.abstract_redirect = Auth0.redirect_uri_business;


        console.log('User Auth0 Callback of: ' + Auth0.callback_uri)
        console.log('User Auth0 Redirect of: ' + this.abstract_redirect)


    }

    notify(msg, color = 'primary', loading = false) {


        //do bothing
    }

    stringToFunction = function (str) {
        var arr = str.split('.');

        var fn = (window || this);
        for (var i = 0, len = arr.length; i < len; i++) {
            fn = fn[arr[i]];
        }

        if (typeof fn !== 'function') {
            throw new Error('function not found');
        }

        return fn;
    };


    set(key, token: any) {


        let promise = null;

        if (null != token && typeof token === 'object') {

            promise = this.storage.set(key, this.jsonConvert.serializeObject(token));
        } else {
            promise = this.storage.set(key, token);

        }

        return promise;

    }

    get(key, type?: any) {

        return new Promise((resolve, reject) => {
            this.notify('Checking cache');


            this.storage.get(key).then((result: any) => {


                if (result) {


                    /*

                    NOTE:  you have to use conmpression.  when the code gets obvuscated, the function names disappear.

                     */
                    if (null != type && type._reflection) {

                        const MyClass = this.stringToFunction(type._reflection);
                        const instance = new MyClass();


                        resolve(this.jsonConvert.deserializeObject(result, instance));
                    } else if (null != type) {

                        resolve(this.jsonConvert.deserializeObject(result, type));
                    } else {
                        resolve(result);
                    }

                } else {
                    reject()
                }


            }).catch((error) => {
                console.dir(error);
                reject()
            })
        })


    }

    remove(key) {
        return this.storage.remove(key);
    }


    // Create an observable of Auth0 instance of client
    auth0Client$ = (from(
        createAuth0Client({
            domain: Auth0.domain,
            client_id: Auth0.clientId,
            redirect_uri: Auth0.callback_uri,
            audience: Auth0.audience
//            screen_hint: 'signup'
        }) 
            // createAuth0Client({
            //     domain: Auth0.domain,
            //     client_id: Auth0.clientId,
            //     redirect_uri: Auth0.callback_uri,
            //     audience: Auth0.audience,
            //     // screen_hint: 'signup',
            //     connection: "sms"
            // }) 
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    );


    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap(res => this.loggedIn = res)
    );


    handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.handleRedirectCallback().catch(() => this.router.navigate(['desktop/home']))))
    )
    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();
    // Create a local property for login status
    loggedIn: boolean = null;
    private abstract_redirect: string;


// When calling, options can be passed if desired
// https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options))),
            tap(user => this.userProfileSubject$.next(user))
        );
    }

// When calling, options can be passed if desired
// https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
    getTokenSilently$(options?): Observable<string> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
        );
    }

    localAuthSetup() {
// This should only be called on app initialization
// Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return this.getUser$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
            // If authenticated, response will be user object
            // If not authenticated, response will be 'false'
            console.log( " ===== localAuthSetup response " , response)
            this.loggedIn = !!response;
        });
    }

    login(redirectPath: string = '/', params: any = {}, queryParams: any = {}, connection?) {
// A desired redirect path can be passed to login method
// (e.g., from a route guard)
// Ensure Auth0 client instance exists


        console.log('redirectPath: ' + redirectPath);

        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            (<any>window).gtag('event', 'Auth0', {
                event_category: 'Login'
            });

            //gets anything from path and passes it as needed
            console.log(params);


            let options: any = {
                redirect_uri: Auth0.callback_uri,
                appState: {target: redirectPath, params: params, queryParams: queryParams}
            }
            if (connection) options.connection = connection

            client.loginWithRedirect(options);

        });
    }


    hasJsonStructure(str) {
        if (typeof str !== 'string') return false;
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]'
                || type === '[object Array]';
        } catch (err) {
            return false;
        }
    }

    handleAuthCallback() {


        const qs = queryString.parse(`${window.location.search}`);
        console.dir(qs)

        const that = this;

        (<any>window).gtag('event', 'Auth0', {
            event_category: 'Callback'
        });


// Only the callback component should call this method
// Call when app reloads after user logs in with Auth0
        let targetRoute: string; // Path to redirect to after login processsed
        let params: any; // Path to redirect to after login processsed
        let queryParams: any; // Path to redirect to after login processsed

        const authComplete$ = this.handleRedirectCallback$.pipe(
            // Have client, now call method to handle auth callback redirect
            tap(cbRes => {

                console.log('cbRes');
                console.dir(cbRes);
                console.dir(this.isAuthenticated$)


                if (cbRes && cbRes !== false && cbRes !== true) {
                    // Get and set target redirect route from callback results
                    targetRoute = cbRes && cbRes.appState.target ? cbRes.appState.target : '/';
                    params = cbRes.appState && cbRes.appState.params ? cbRes.appState.params : {};
                    queryParams = cbRes.appState && cbRes.appState.queryParams ? cbRes.appState.queryParams : {};

                    console.log('targetRoute:' + targetRoute);
                    console.dir(params);

                } else if (cbRes && cbRes === true) {


                    if (this.hasJsonStructure(cbRes)) {


                        console.log('Using menu' + JSON.stringify(cbRes));

                        if (qs && qs.state && this.hasJsonStructure(qs.state)) {
                            const state = JSON.parse(qs.state);

                            targetRoute = state && state.target ? state.target : '/';
                            params = state && state.params ? state.params : {};
                            queryParams = state && state.queryParams ? state.queryParams : {};

                        }
                        //this.router.navigate(['members/menu']);

                    }

                } else if (qs && qs.state && this.hasJsonStructure(qs.state)) {


                    console.log('Using menu' + JSON.stringify(cbRes));

                    if (qs && qs.state && this.hasJsonStructure(qs.state)) {
                        const state = JSON.parse(qs.state);

                        targetRoute = state && state.target ? state.target : '/';
                        params = state && state.params ? state.params : {};
                        queryParams = state && state.queryParams ? state.queryParams : {};

                    }
                    //this.router.navigate(['members/menu']);


                    //this.router.navigate(['']);
                }

            }),
            concatMap(() => {
                // Redirect callback complete; get  user and login status
                return combineLatest(
                    this.getUser$(),
                    this.isAuthenticated$,
                    from(new Promise(resolve => resolve(targetRoute))),
                    from(new Promise(resolve => resolve(params))),
                    from(new Promise(resolve => resolve(queryParams)))
                );
            })
        );

        console.log('targetRoute:' + targetRoute);
        console.dir(params);

// Subscribe to authentication completion observable
// Response will be an array of user and login status
        authComplete$.subscribe(([user, loggedIn, target, params]) => {


            console.log('Finally!');
            console.log(target);
            console.dir(params);
            console.dir(queryParams);

            let target_url: any = target;
 

            const navigationExtras: NavigationExtras = {
                skipLocationChange: false,
                state: {target: target, params: params},
                queryParams: queryParams
            };

            console.log( "auth0 business service target_url " + target_url + " ; target = " + target );




            if (target_url && target_url.startsWith('http')) {

                //this.router.navigateByUrl([target], navigationExtras);

                var url_parts = url.parse(target_url);
                console.log(url_parts);
                console.log(url_parts.pathname);
                this.router.navigate([url_parts.pathname], navigationExtras);
            } else {

                if (!target) {
                    this.router.navigate(['desktop/home'], navigationExtras);
                } else {
                    this.router.navigate([target], navigationExtras);
                }

            }

            /*

                        this.set(this.deviceAuth, false).then(() => {
                            this.get(this.deviceAuth).then((result) => {


                                console.log('Result' + target);

                                if (result) {

                                    console.log('Using target' + target);

                                    // Redirect to target route after callback processing
                                    this.router.navigate([target], navigationExtras);


                                } else {


                                    this.router.navigate(['auth0-two-factor'], navigationExtras);

                                }


                            }).catch((err) => {
                                this.router.navigate(['auth0-two-factor'], navigationExtras);
                            })
                        })





                    console.log("Using target" + target);

                        // Redirect to target route after callback processing
                        this.router.navigate([target], navigationExtras);


                                    this.get(this.deviceAuth).then((result) => {


                                        console.log('Result' + target);

                                        if (result) {

                                            console.log('Using target' + target);

                                            // Redirect to target route after callback processing
                                            this.router.navigate([target], navigationExtras);


                                        } else {


                                            this.router.navigate(['auth0-2fa'], navigationExtras);

                                        }


                                    })


                         */


            //this.router.navigate(['members', 'menu', 'guests-init']);


        });
    }

    logout(path?) {


// Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {

            (<any>window).gtag('event', 'Auth0', {
                event_category: 'Logout'
            });

            // Call method to log out
            client.logout({
                client_id: Auth0.clientId,
                returnTo: `${window.location.origin}/logout-success`
            });
        });
    }


}