import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Facepay } from '../../environments/environment';
import * as encodeUrl from 'encodeurl';

import { Plugins } from '@capacitor/core';
import {FacepayService} from './facepay.service';
import {FacepayClient} from '@facepay/api-client';
const { Browser } = Plugins;
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Business} from '@facepay/api-client/build/facepay/dao/business';
import {Guest} from '@facepay/api-client/build/facepay/dao/guest';


@Injectable({
    providedIn: 'root'
})
export class ServicesService extends BaseService {




    private domain = Facepay.domain;
    private url_base = Facepay.services;



    private salesforceSyncUrl = this.url_base + '/salesforce/sync';
    private ivUrl = this.url_base + '/crypto/iv';
    private ipUrl = this.url_base + '/utils/ipv4';
    private documentUrl = this.url_base + '/crypto/document';
    private documentRetrieveUrl = this.url_base + '/crypto/document-retrieve';
    private encryptUrl = this.url_base + '/crypto/encrypt';
    private decryptUrl = this.url_base + '/crypto/decrypt';
    private fencryptUrl = this.url_base + '/crypto/fencrypt';
    private fdecryptUrl = this.url_base + '/crypto/fdecrypt';
    private addOptionsUrl = this.url_base + '/async/add/options';
    private inviteUrl = this.url_base + '/async/invite';
    private addGuestUrl = this.url_base + '/async/addGuest';
    private activateCreateUrl = this.url_base + '/activate/create';
    private activateVerifyUrl = this.url_base + '/activate/verify';
    private activateConsumeUrl = this.url_base + '/activate/consume';


    private loginAddUrl = this.url_base + '/login';
    private countUrl = this.url_base + '/count';
    private loginLastUrl = this.url_base + '/login/business';
    private businessLoginLastUrl = this.domain + '/login/business';




    private getSystemNotificationUrl = this.url_base + '/system/notification';
    private postSystemNotificationUrl = this.url_base + '/system/notification';
    private deleteSystemNotificationUrl = this.url_base + '/system/notification';


    constructor(public storage: Storage, public httpClient: HttpClient) {
        super(storage, httpClient);

    }


    public whoami() {

        return  this.invoke(this.ipUrl)
        /*

        return new Promise((resolve, reject) => {
            fetch('https://api.ipstack.com/174.62.111.137?access_key=91a49454a55e8248772f6df24a9db827')
                .then(resp => resp.json())
                .then(ipv4 => resolve(ipv4)).catch(err => reject(err));
        });
        */
    }

    public iv() {
        return this.invoke(this.ivUrl);
    }
    public asyncOptions(options) {

        console.log("add options");
        console.dir(options);

        return this.invoke(this.addOptionsUrl,options);
    }
    public document(data) {
        return this.invoke(this.documentUrl,data);
    }
    public salesforceSync(data) {
        return this.invoke(this.salesforceSyncUrl,data);
    }

    public count(key, address, json) {
        const data = {key: key, address: address, data: json}

        return this.invoke(this.countUrl,data);

    }

    public async findCount(address, which, duration) {
        let link = this.countUrl + "/" + address + "/" + which + "/" + duration
        let result = await this.invoke(link);
        return result;
        //return this.invoke(link);

    }



    public login(business, employee) {

        const data = {business: {address: business.getAddress(), employee: {address: employee.getAddress()}}}

        return this.invoke(this.loginAddUrl,data);
    }
    public lastLogin(business, employee) {

        const url = `${this.loginLastUrl}/${business.getAddress()}/employee/${employee.getAddress()}`

        return this.invoke(url);
    }
    public businessLastLogin(business) {

        const url = `${this.loginLastUrl}/${business.getAddress()}`
        console.log( " ====== businessLastLogin " + url  );
        return this.invoke(url);
    }



    public documentRetrieve(data) {
        return this.invoke(this.documentRetrieveUrl,data);
    }
    public encrypt(iv, text) {
        return this.invoke(this.encryptUrl,{iv: iv, text: text});
    }
    public decrypt(iv, text) {
        return this.invoke(this.decryptUrl,{iv: iv, encrypted: text});
    }
    public fencrypt(text) {
        return this.invoke(this.fencryptUrl,{ text: text});
    }
    public fdecrypt(text) {
        return this.invoke(this.fdecryptUrl,{ encrypted: text});
    }

    public getSystemNotification() {
        // return this.invoke(this.getSystemNotificationUrl).then( ( result:any  )=>{
        //     if (result && result.msg) {
        //         return new Promise( ( resolve, reject) =>{                    
        //             return resolve( result )
        //             }
        //         )
        //     }else{
        //         return new Promise( ( resolve, reject) =>{
        //             let returnStr = {
        //                 msg: `<div><style scoped>@import url(https://fonts.googleapis.com/css?family=Trirong);div{font-family:Trirong,serif}.greeting{font-size:24pt;font-weight:700}.text{font-size:12pt}</style><div class='greeting'>Hello from Facepay</div><div class='text'>This is your priority pickup.</div></div>`
        //                 // msg: `<div><style scoped>@import url(https://fonts.googleapis.com/css?family=Trirong);div{font-family:Trirong,serif}.greeting{font-size:100pt;font-weight:700}.text{font-size:12pt}</style><div class='serviceAdvisorText' style='font-color:black '>Hello</div><div class='greeting'><a href=\"http://www.facepay.io\">Facepay.io</a><img src=\"https://facepay.io/i/favicon.png\"/></div></div>`
        //                 // msg: `<div>Hello<br/><a href="www.facepay.io">Facepay111.io</a><img src="https://facepay.io/i/favicon.png"/></div>`
        //             };
        //             return resolve( 
        //                     returnStr
        //                 )
        //             }
        //         )
        //     }
        // } );
        
        return this.invoke(this.getSystemNotificationUrl);
    }
    public postSystemNotification(json) {
        return this.invoke(this.postSystemNotificationUrl,json);
    }
    public deleteSystemNotification() {
        return this.deleteInvoke(this.deleteSystemNotificationUrl);
    }


    public activateCreate(options) {
        return this.invoke(this.activateCreateUrl,options);
    }
    public activateVerify(options) {
        return this.invoke(this.activateVerifyUrl,options);
    }
    public activateConsume(options) {
        return this.invoke(this.activateConsumeUrl,options);
    }




    public addGuestAsync(business: Business, guest: Guest, user, metadata, cell, uuid, public_token, institution) {


        console.dir(guest);

        let json = {
            business: { address: business.getAddress(), name: business.getName(), options: {"enc.iv": business.getOption("enc.iv")}},
            guest: null,
            user: user,
            cell: cell,
            uuid: uuid,
            metadata: metadata,
            public_token: public_token,
            institution: institution

        }

        if ( guest ) {
            json.guest = { address: guest.getAddress(), person: { address: guest.getPerson().getAddress(), cell: guest.getPerson().getCell()}}
        }

        return this.invoke(this.addGuestUrl,json);
    }




    public invite(business, guest) {


        const json = {
            business: { address: business.getAddress(), name: business.getName(), gravatar: business.getOption('gravatar')},
            guest: { person: { cell: guest.getPerson().getCell()}}
        }

        return this.invoke(this.inviteUrl,json);
    }



}


